<style lang="scss" scoped>
.sticky-header {
  position: sticky;
  top: 0;
  padding-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 2px;
  background: linear-gradient($primary, 10px, #fff 10px);
  height: 7.5rem;

  > :first-child {
    margin-left: 2rem;
  }
}
.search-fields {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

form {
  display: flex;
}
</style>

<template>
  <b-navbar class="sticky-header">
    <template slot="brand">
      <Logo width="140px" />
    </template>
    <template slot="start">
      <b-field grouped class="search-fields">
        <form v-on:submit.prevent>
          <b-field>
            <b-input
              v-bind:placeholder="$t('searchHeader.searchForCourse')"
              v-model="newKeyword"
              type="search"
              icon-right="magnify"
            >
            </b-input>
          </b-field>

          <b-field>
            <b-autocomplete
              :placeholder="$t('searchHeader.location')"
              v-model="newLocation"
              type="search"
              :data="locationSuggestions"
              icon-right="map-marker-outline"
              clearable
            >
            </b-autocomplete>
          </b-field>

          <b-field grouped class="field-container">
            <b-dropdown v-model="newDistance">
              <b-input
                :disabled="newLocation === undefined || newLocation === ''"
                class="distance-search"
                type="button"
                slot="trigger"
                icon-right="arrow-expand"
                :value="
                  newLocation
                    ? newDistance + ' km'
                    : $t('searchHeader.distance')
                "
              >
              </b-input>

              <b-dropdown-item :value="10" aria-role="listitem">
                <span>10 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="25" aria-role="listitem">
                <span>25 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="50" aria-role="listitem">
                <span>50 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="100" aria-role="listitem">
                <span>100 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="250" aria-role="listitem">
                <span>250 km</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>

          <b-field>
            <b-button
              @click="search"
              native-type="submit"
              type="is-primary"
              class="is-uppercase search-button"
              >{{ $t('searchHeader.search') }}</b-button
            >
          </b-field>
        </form>
      </b-field>
    </template>
    <template slot="end">
      <b-navbar-item tag="router-link" :to="{ path: `/${language}/search` }">
        {{ $t('header.browse') }}</b-navbar-item
      >
      <b-navbar-item
        tag="router-link"
        :to="{ path: `/${language}/service-providers` }"
      >
        {{ $t('header.serviceProviders') }}
      </b-navbar-item>
      <LanguageSelection />
    </template>
  </b-navbar>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import LanguageSelection from '../../header/language-selection.vue';
import Logo from '../../header/logo.vue';
import { router } from '../../../router';
import { getLocationSuggestions } from '../../../utils/location-utils';
import useRouter from '../../../hooks/useRouter';

export default defineComponent({
  name: 'SearchHeader',
  components: {
    LanguageSelection,
    Logo
  },
  props: {
    keyword: {
      type: String
    },
    distance: {
      type: Number
    },
    location: {
      type: String
    }
  },
  setup: (props) => {
    const { language } = router.currentRoute.params;
    const newKeyword = ref<string | undefined>(props.keyword);
    const newLocation = ref<string | undefined>(props.location);
    const newDistance = ref<number>(props.distance ?? 25);
    const locationSuggestions = computed(() =>
      getLocationSuggestions(newLocation.value)
    );
    const { pushRoute } = useRouter();

    watch(
      () => props.keyword,
      () => {
        newKeyword.value = props.keyword;
      }
    );

    watch(
      () => props.location,
      () => {
        newLocation.value = props.location;
      }
    );

    watch(
      () => props.distance,
      () => {
        newDistance.value = props.distance ?? 25;
      }
    );

    const search = async () =>
      pushRoute({
        keyword: newKeyword.value,
        location: newLocation.value,
        distance: newLocation.value && newDistance.value.toString()
      });

    return {
      language,
      search,
      newKeyword,
      newLocation,
      newDistance,
      locationSuggestions
    };
  }
});
</script>
