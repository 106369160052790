<style lang="scss" scoped>
section {
  padding-top: 1rem;
}
.skeleton-wrapper {
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.registration-box-skeleton {
  width: 20rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 3rem;
  overflow-wrap: normal;
  margin-left: 3rem;
}

.skeleton-content-wrapper {
  width: 65%;
}

.course-title {
  font-size: 3rem !important;
}

.title-info {
  display: flex;
  align-items: center;
}

.dot {
  border-radius: 50%;
  height: 5px;
  width: 5px;
  background: black;
  margin: 0.5rem;
}

.main-content {
  margin-right: 7%;
  width: 65%;
}

.location {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.course-map {
  width: 100%;
  height: 30rem;
}
.registration-box-desktop {
  position: sticky;
  top: 60px;
  z-index: 1000;
  width: 20rem;
  align-self: flex-start;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 3rem;
  overflow-wrap: normal;
}

.registration-box-mobile {
  display: none;
}

.wrapper {
  max-width: 100%;
  padding: 3rem 7%;
  display: flex;
  flex-direction: row;
}

.course-tenant {
  max-width: 65%;
}

.description {
  word-wrap: break-word;
}

.collapse-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;
  padding-right: 0;
}

.collapse-title-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  margin-top: 0.3rem;
  flex-wrap: wrap;
}

.collapse-title-icon {
  margin-right: 0.3rem;
}

.collapse-title-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1.5rem;
}

.collapse-card {
  box-shadow: none;
}

.collapse-card-header {
  box-shadow: none;
  :hover {
    color: $primary;
  }
}

@media (min-width: 1500px) {
  .wrapper {
    padding: 3rem 8%;
  }
  .main-content {
    width: 75%;
  }
  .registration-box-desktop {
    top: 80px;
  }
}

@media (min-width: 1200px) {
  .main-content {
    width: 70%;
  }
  .registration-box-desktop {
    top: 70px;
  }
}

@media (min-width: 1026px) {
  .header-mobile {
    display: none;
  }
}

@media (max-width: 1025px) {
  .skeleton-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .registration-box-skeleton {
    margin-left: 0;
    margin-top: 3rem;
    width: 65%;
  }

  .course-title {
    font-size: 2rem !important;
  }
  .title-info {
    font-size: 1rem;
  }

  .registration-box-mobile {
    display: relative;
    padding: 2rem;
    margin-top: 2rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .header-desktop {
    display: none;
  }

  .side-icon {
    display: flex;
    align-self: center;
  }

  .registration-box-mobile > span {
    padding-bottom: 0.2rem;
  }

  .registration-box-mobile > button {
    margin-top: 2rem;
  }

  .registration-box-desktop {
    display: none;
  }
  .wrapper {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .main-content {
    width: 100%;
  }
  aside {
    display: none;
  }
}

@media (max-width: 700px) {
  .title {
    font-size: 1.8rem;
  }
  .title-info {
    font-size: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .skeleton-wrapper {
    width: 100%;
  }
  .registration-box-skeleton {
    width: 90%;
  }
  .skeleton-content-wrapper {
    width: 90%;
  }
  .course-tenant {
    max-width: 100%;
  }
}
</style>

<style lang="scss">
div.lessons-table div.table-wrapper table.table > thead {
  display: none !important;
}

div.lessons-table tbody tr > td {
  flex-direction: row-reverse;
}
</style>

<template>
  <div>
    <Header class="header-desktop" />
    <MobileHeader class="header-mobile" />
    <div v-if="isLoading">
      <section class="skeleton-wrapper">
        <div class="skeleton-content-wrapper">
          <b-skeleton width="100%" height="3rem" :animated="true"> </b-skeleton>
          <b-skeleton width="100%" height="20rem" :animated="true"></b-skeleton>
        </div>
        <div class="registration-box-skeleton card">
          <b-skeleton width="100%" height="3rem" :animated="true"></b-skeleton>
          <b-skeleton width="100%" height="10rem" :animated="true"></b-skeleton>
        </div>
      </section>
    </div>
    <div v-if="!isLoading && course" class="wrapper">
      <section class="main-content content">
        <h1 class="title course-title" v-html="course.name"></h1>
        <h6 v-if="tenantName" class="title is-6 title-info">
          <router-link
            class="is-primary"
            :to="{
              path: `/${language}/search?serviceproviders=${course.tenant}`
            }"
          >
            <span class="course-tenant">{{ tenantName }}</span>
          </router-link>
          <div v-if="course.teacher" class="dot"></div>
          {{ course.teacher }}
        </h6>
        <div class="description" v-html="course.description"></div>
        <hr />
        <div
          :key="period.name + period.begins"
          v-for="period in sortBy(['begins', 'ends'], course.periods)"
        >
          <LessonsCollapse
            v-if="period.keywords.some((keyword) => keyword.includes('period'))"
            :period="period"
          />
        </div>
        <div v-if="course.periods.length === 0 && course.lessons.length > 0">
          <LessonsCollapse :course="course" />
        </div>
        <hr v-if="course.periods.length !== 0 || course.lessons.length !== 0" />
        <RegistrationBox
          :course="course"
          class="registration-box-mobile card"
        />
        <div v-if="course.location" class="location">
          <b-icon icon="map-marker-outline" />
          <span>{{
            [
              course.location.name,
              course.location.address,
              course.location.city
            ]
              .filter(Boolean)
              .join(', ')
          }}</span>
          <span></span>
        </div>
        <div v-if="course.location">
          <CourseMap
            v-if="course.location.latlon"
            class="course-map"
            :coordinates="course.location.latlon"
            :text="course.location.name"
          />
        </div>
      </section>

      <RegistrationBox :course="course" class="registration-box-desktop card" />
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  watch
} from '@vue/composition-api';
import { find, sortBy } from 'lodash/fp';

import { HellewiCatalogItemType } from '../api';
import { router } from '../router';
import { useGetCourse } from '../hooks/useCourseApi';
import { stateHasError, stateIsLoading } from '../utils/api-utils';

import Header from '../components/header/header.vue';
import MobileHeader from '../components/header/header-mobile.vue';
import Footer from '../components/footer.vue';
import CourseMap from '../components/course-list/course-map.vue';
import RegistrationBox from '../components/course/registration-box.vue';
import LessonsCollapse from '../components/course/lessons-collapse.vue';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Course',
  components: {
    Header,
    MobileHeader,
    Footer,
    CourseMap,
    RegistrationBox,
    LessonsCollapse
  },
  setup() {
    const { language } = router.currentRoute.params;
    const { response: course, execute: getCourse, state } = useGetCourse();

    const id = computed(() => router.currentRoute.params.id);
    const hasError = stateHasError(state);
    const isLoading = stateIsLoading(state);

    const checkError = (error: boolean) => {
      if (error) {
        router.push({
          name: '404'
        });
      }
    };

    const tenantName = computed<string | undefined>(() => {
      const tenantCi = find(
        (ci) => ci.type === HellewiCatalogItemType.Tenant,
        course.value?.catalogitems
      );
      return tenantCi?.name;
    });

    onBeforeMount(() => {
      getCourse(id.value);
      checkError(hasError.value);
    });
    watch(id, getCourse);
    watch(hasError, checkError);

    return {
      course,
      sortBy,
      tenantName,
      isLoading,
      language
    };
  }
});
</script>
