import { find } from 'lodash/fp';

import { HellewiCoursePartial, HellewiCoursePrice } from '../api';

export const getDefaultPrice = (
  course: Pick<HellewiCoursePartial, 'prices'> | undefined
): HellewiCoursePrice | undefined => {
  if (!course || !course.prices) {
    return undefined;
  }

  return find({ _default: true }, course.prices);
};

export const getPriceEuros = (
  price: Pick<HellewiCoursePrice, 'amount'> | undefined
): number | undefined => {
  if (price != null && price.amount != null) {
    return price.amount / 100;
  } else {
    return undefined;
  }
};
