<template>
  <router-view :apiConfiguration="apiConfiguration"></router-view>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  watch
} from '@vue/composition-api';

import { Configuration } from '../api';
import { useCourseApi } from '../hooks/useCourseApi';
import { useCatalogApi } from '../hooks/useCatalogApi';
import { useTenantApi } from '../hooks/useTenantApi';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Language',
  props: {
    language: {
      type: String,
      required: true
    }
  },
  setup: (props, { root }) => {
    const language = props.language;
    const apiConfiguration = computed(() => {
      const proto = location.protocol;
      const host = location.hostname;
      const port = location.port
        ? location.port === '8080' // vue-devbuild => use dev-backend
          ? ':9041'
          : `:${location.port}`
        : '';

      return new Configuration({
        basePath: `${proto}//${host}${port}/v1/${language}`
      });
    });

    const {
      changeConfiguration: changeConfigurationTenantApi
    } = useTenantApi();

    const {
      changeConfiguration: changeConfigurationCourseApi
    } = useCourseApi();

    const {
      changeConfiguration: changeConfigurationCatalogApi
    } = useCatalogApi();

    const changeConfigurations = (configuration: Configuration) => {
      changeConfigurationTenantApi(configuration);
      changeConfigurationCourseApi(configuration);
      changeConfigurationCatalogApi(configuration);
    };

    onBeforeMount(() => {
      changeConfigurations(apiConfiguration.value);
      // setup vue-i18n language here, api language is in respective apis
      root.$i18n.locale = props.language;
    });

    watch(apiConfiguration, changeConfigurations);

    return { apiConfiguration };
  }
});
</script>
