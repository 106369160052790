<style scoped>
.cards {
  display: grid;
  justify-content: end;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 18rem);
  padding-right: 2rem;
}

.noresults {
  display: grid;
  justify-content: end;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, 56rem);
  padding-right: 2rem;
}

@media (max-width: 1450px) {
  .cards {
    grid-template-columns: repeat(2, 18rem);
  }
  .noresults {
    grid-template-columns: repeat(auto-fill, 36rem);
  }
}

@media (max-width: 1250px) {
  .cards {
    padding: 0;
    align-content: center;
    justify-content: center;
    margin-right: 0 !important;
    grid-template-columns: repeat(auto-fill, 18rem);
  }
  .noresults {
    padding: 0;
    align-content: center;
    justify-content: center;
    margin-right: 0 !important;
    grid-template-columns: repeat(auto-fill, 18rem);
  }
}
</style>

<template>
  <div>
    <div v-if="!isLoading && !isEmpty(courses)" class="cards">
      <CourseCard
        v-for="course in courses"
        :key="course.id"
        :course="course"
        :participantCount="course.participantcount"
      />
    </div>
    <div v-else-if="!isLoading" class="noresults content">
      <div>
        <h4>
          {{ $t('courseList.noResults') }}
        </h4>
        <p>
          {{ $t('courseList.getMoreResults') }}
        </p>
      </div>
    </div>
    <div v-else class="cards">
      <CourseCard isLoading />
      <CourseCard isLoading />
      <CourseCard isLoading />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import { isEmpty } from 'lodash/fp';

import { ListCoursesResponseCourse } from '../../hooks/useCourseApi';

import CourseCard from './course-card.vue';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Courselist',
  components: { CourseCard },
  props: {
    courses: {
      type: Array as PropType<ListCoursesResponseCourse[]>,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  setup: () => ({ isEmpty })
});
</script>
