<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

import { default as messages } from '../frontend-assets/i18n.json';

export default defineComponent({
  name: 'App',
  metaInfo: {
    title: messages.fi.htmlTitle
  }
});
</script>
