<style lang="scss" scoped>
.collapse-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;
  padding-right: 0;
}

.collapse-title-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  margin-top: 0.3rem;
  flex-wrap: wrap;
}

.collapse-title-icon {
  margin-right: 0.3rem;
}

.collapse-title-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1.5rem;
}

.collapse-card {
  box-shadow: none;
}

.collapse-card-header {
  box-shadow: none;
  :hover {
    color: $primary;
  }
}

.weekdayLong {
  text-transform: capitalize;
}
</style>

<style lang="scss">
div.lessons-table div.table-wrapper table.table > thead {
  display: none !important;
}

div.lessons-table tbody tr > td {
  flex-direction: row-reverse;
}
</style>

<template>
  <b-collapse
    class="card collapse-card"
    animation="slide"
    :aria-id="lessons.name + lessons.begins"
    :open="false"
  >
    <div
      slot="trigger"
      slot-scope="props"
      class="card-header collapse-card-header"
      role="button"
      :aria-controls="lessons.name + lessons.begins"
    >
      <span class="card-header-title collapse-title-container">
        <span v-if="lessons.name">{{ lessons.name + ' ' }}</span>
        <div class="collapse-title-details">
          <div
            class="collapse-title-detail"
            v-if="lessons.begins && lessons.ends"
          >
            <b-icon
              class="icon collapse-title-icon"
              icon="calendar-today"
              size="is-small"
            />
            <p>{{ lessons.begins | dateRange(lessons.ends) }}</p>
          </div>
          <div class="collapse-title-detail" v-if="lessons.lessoncount">
            <b-icon
              class="icon collapse-title-icon"
              icon="timer-sand"
              size="is-small"
            />
            <p>
              {{ $tc('lessonsCollapse.lessons', lessons.lessoncount) }}
            </p>
          </div>
        </div>
      </span>
      <a class="card-header-icon">
        <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"> </b-icon>
      </a>
    </div>
    <div class="card-content">
      <div class="content">
        <span v-if="!lessons.lessons">
          {{ $t('lessonsCollapse.lessonsNotprovided') }}
        </span>
        <div v-if="lessons.lessons" class="lessons-table">
          <b-table :data="lessons.lessons" :mobile-cards="true">
            <b-table-column custom-key="day" v-slot="props">
              <span class="weekdayLong">
                {{ $d(new Date(props.row.begins), 'weekdayLong') }}
                {{
                  new Date(props.row.begins)
                    | dateRange(new Date(props.row.ends))
                }}
              </span>
            </b-table-column>
            <b-table-column custom-key="time" v-slot="props">
              {{
                new Date(props.row.begins) | timeRange(new Date(props.row.ends))
              }}
            </b-table-column>
            <b-table-column custom-key="place" v-slot="props">
              <span v-if="props.row.location">
                {{
                  [props.row.location.name, props.row.location.address]
                    .filter(Boolean)
                    .join(', ')
                }}
              </span>
              <span v-else>
                {{ $t('lessonsCollapse.placenotprovided') }}
              </span>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </b-collapse>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { HellewiCoursePartial, HellewiCoursePeriod } from '@/frontend-src/api';
import setISODay from 'date-fns/setISODay';

export default defineComponent({
  name: 'lessons-collapse',
  props: {
    period: {
      type: Object as PropType<HellewiCoursePeriod | undefined>
    },
    course: {
      type: Object as PropType<HellewiCoursePartial | undefined>
    }
  },
  setup(props) {
    const lessons = computed(() => {
      if (props.period) {
        return {
          name: props.period.name,
          begins: props.period.begins,
          ends: props.period.ends,
          lessoncount: props.period.lessoncount,
          lessons:
            props.period.lessons && props.period.lessons?.length > 0
              ? props.period.lessons
              : undefined
        };
      } else if (props.course) {
        return {
          name: undefined,
          begins: props.course.begins,
          ends: props.course.ends,
          lessoncount: props.course.lessons.length,
          lessons:
            props.course.lessons && props.course.lessons?.length > 0
              ? props.course.lessons
              : undefined
        };
      } else {
        return [];
      }
    });
    return {
      lessons,
      setISODay
    };
  }
});
</script>
