import {
  computed,
  ComputedRef,
  onBeforeMount,
  ref,
  Ref,
  watch
} from '@vue/composition-api';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { BNoticeComponent } from 'buefy/types/components';

import { Configuration } from '../api';

import { translate } from './misc-utils';

export enum RequestState {
  Uninitialized = 'UNINITIALIZED',
  Initialized = 'INITIALIZED',
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Error = 'ERROR'
}

export type Api<T> = () => {
  api: Ref<T | undefined>;
  changeConfiguration: (configuration: Configuration) => void;
};

export type ApiEndpoint<I, O> = () => {
  initial: O;
  state: Ref<RequestState>;
  response: Ref<O>;
  execute: (input: I) => void;
  // hasError and isLoading would fit here, but unfortunately watchers' and
  // computed variables' lifecycle is limited to the component in which they
  // are loaded, so their watchers will be stopped when the component is
  // unmounted.
  //
  // So they have to be created for each component separately like this:
  // const { response: course, execute: getCourse, state } = useGetCourse();
  // const isLoading = stateIsLoading(state);
};

export const stateHasError = (state: Ref<RequestState>): ComputedRef<boolean> =>
  computed(() => state.value === RequestState.Error);

export const stateIsLoading = (
  state: Ref<RequestState>
): ComputedRef<boolean> => computed(() => state.value === RequestState.Loading);

export const ApiEndpointInitialization = <O>(
  api: Ref,
  state: Ref<RequestState>,
  response: Ref<O>,
  initial: O
) => {
  const initialize = () => {
    if (api.value) {
      state.value = RequestState.Initialized;
    } else {
      state.value = RequestState.Uninitialized;
    }
    response.value = initial;
  };

  onBeforeMount(() => {
    if (state.value === RequestState.Uninitialized) {
      initialize();
    }
  });
  watch(api, initialize); // if API is changed, reset everything
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useErrorToast = (ctx: any) => {
  const warnComponents = ref<BNoticeComponent[]>([]);
  const warnToast = (i18nKey: string) =>
    warnComponents.value.push(
      Snackbar.open({
        message: translate(ctx, i18nKey),
        duration: 5000,
        type: 'is-warning',
        position: 'is-top',
        actionText: 'OK',
        indefinite: true,
        queue: true
      })
    );

  const clearErrorToasts = () => {
    for (const component of warnComponents.value) {
      component.close();
    }
    warnComponents.value = [];
  };

  return { warnToast, clearErrorToasts };
};
