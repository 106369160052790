import ajoneuvoImage from '../../../frontend-assets/luokitukset-kuvat/23ajoneuvo-ja_kuljetusala.png';
import historiaImage from '../../../frontend-assets/luokitukset-kuvat/13historia_ja_arkeologia.png';
import humanistinenImage from '../../../frontend-assets/luokitukset-kuvat/24humanistinen_ja_kasvatusala.png';
import kadentaidotImage from '../../../frontend-assets/luokitukset-kuvat/2kaden_taidot.png';
import kansalaisImage from '../../../frontend-assets/luokitukset-kuvat/29kansalais-ja_jarjestotoiminta.png';
import kieletImage from '../../../frontend-assets/luokitukset-kuvat/4kielet.png';
import kirjallisuusImage from '../../../frontend-assets/luokitukset-kuvat/11kirjallisuus.png';
import kotitalousImage from '../../../frontend-assets/luokitukset-kuvat/17kotitalous-ja_kuluttajapalvelut.png';
import kulttuuriImage from '../../../frontend-assets/luokitukset-kuvat/19muu_kulttuurialan_koulutus.png';
import kuvataideImage from '../../../frontend-assets/luokitukset-kuvat/5kuvataide.png';
import liiketalousImage from '../../../frontend-assets/luokitukset-kuvat/28liiketalous_ja_kauppa.png';
import liikuntaImage from '../../../frontend-assets/luokitukset-kuvat/1liikunta_ja_urheilu.png';
import luokittelemattomatImage from '../../../frontend-assets/luokitukset-kuvat/9luokittelemattomat.png';
import luontoImage from '../../../frontend-assets/luokitukset-kuvat/22luonto-ja_ymparistoala.png';
import musiikkiImage from '../../../frontend-assets/luokitukset-kuvat/3musiikki.png';
import opetusImage from '../../../frontend-assets/luokitukset-kuvat/12opetus.png';
import perusopetusImage from '../../../frontend-assets/luokitukset-kuvat/20perusopetus.png';
import poliisiImage from '../../../frontend-assets/luokitukset-kuvat/26poliisi-ja_vartiointiala.png';
import puutarhaImage from '../../../frontend-assets/luokitukset-kuvat/16puutarhatalous_ja_puutarhanhoito.png';
import ruokaImage from '../../../frontend-assets/luokitukset-kuvat/8ruoka_ja_juoma.png';
import sosiaaliImage from '../../../frontend-assets/luokitukset-kuvat/14sosiaali-terveys-ja_liikunta-ala.png';
import tanssiImage from '../../../frontend-assets/luokitukset-kuvat/6tanssi_ja_teatteri.png';
import tektiiliImage from '../../../frontend-assets/luokitukset-kuvat/30tekstiili-ja_vaatetusala.png';
import terveysImage from '../../../frontend-assets/luokitukset-kuvat/10terveys.png';
import tietoImage from '../../../frontend-assets/luokitukset-kuvat/15tieto-ja_tietoliikennetekniikka.png';
import tietotekniikkaImage from '../../../frontend-assets/luokitukset-kuvat/7tietotekniikka.png';
import vapaaaikaImage from '../../../frontend-assets/luokitukset-kuvat/25vapaa-aika-jaa_nuorisotyo.png';
import viestintäImage from '../../../frontend-assets/luokitukset-kuvat/27viestinta-ja_informaatioala.png';
import yhteiskunnallinenImage from '../../../frontend-assets/luokitukset-kuvat/21yhteiskunnalliset_aineet.png';
import yleissivistäväImage from '../../../frontend-assets/luokitukset-kuvat/18muu_yleissivistava_koulutus.png';

export const DEFAULT_COLOR = '#6987C9';
export const DEFAULT_IMAGE = luokittelemattomatImage;

export const educationSectorMappings: {
  [key: number]: { imageUrl: string; name: string; color: string };
} = {
  752: {
    imageUrl: liikuntaImage,
    name: 'Liikunta ja urheilu',
    color: '#23967F'
  },
  201: {
    imageUrl: kadentaidotImage,
    name: 'Kaden taidot',
    color: '#402E2A'
  },
  205: {
    imageUrl: musiikkiImage,
    name: 'Musiikki',
    color: '#6987C9'
  },
  10201: {
    imageUrl: kieletImage,
    name: 'Kielitiede',
    color: '#FFBF00'
  },
  10202: {
    imageUrl: kieletImage,
    name: 'Suomi',
    color: '#D14081'
  },
  10203: {
    imageUrl: kieletImage,
    name: 'Ruotsi',
    color: '#23967F'
  },
  10204: {
    imageUrl: kieletImage,
    name: 'Englanti',
    color: '#402E2A'
  },
  10205: {
    imageUrl: kieletImage,
    name: 'Saksa',
    color: '#FFBF00'
  },
  10206: {
    imageUrl: kieletImage,
    name: 'Ranska',
    color: '#6987C9'
  },
  10207: {
    imageUrl: kieletImage,
    name: 'Venäjä',
    color: '#402E2A'
  },
  10208: {
    imageUrl: kieletImage,
    name: 'Espanja',
    color: '#D14081'
  },
  10209: {
    imageUrl: kieletImage,
    name: 'Italia',
    color: '#23967F'
  },
  10299: {
    imageUrl: kieletImage,
    name: 'Muut kielet',
    color: '#402E2A'
  },
  206: {
    imageUrl: kuvataideImage,
    name: 'Kuvataide',
    color: '#FFBF00'
  },
  505: {
    imageUrl: kuvataideImage,
    name: 'Graafinen ja viestintätekniikka',
    color: '#D14081'
  },
  204: {
    imageUrl: tanssiImage,
    name: 'Tanssi ja teatteri',
    color: '#23967F'
  },
  40201: {
    imageUrl: tietotekniikkaImage,
    name: 'Tietokoneen ajokorttikoulutu',
    color: '#402E2A'
  },
  40299: {
    imageUrl: tietotekniikkaImage,
    name: 'Muu tietotekniikan hyväksikäyttö',
    color: '#6987C9'
  },
  801: {
    imageUrl: ruokaImage,
    name: 'Matkailuala',
    color: '#FFBF00'
  },
  802: {
    imageUrl: ruokaImage,
    name: 'Majoitus- ja ravitsemisala sekä ruoan valmistus',
    color: '#D14081'
  },
  899: {
    imageUrl: ruokaImage,
    name: 'Muu matkailu-, ravitsemis- ja talousalan koulutus',
    color: '#23967F'
  },
  304: {
    imageUrl: luokittelemattomatImage,
    name: 'Tilastointi ja tilastotiede',
    color: '#402E2A'
  },
  401: {
    imageUrl: luokittelemattomatImage,
    name: 'Matematiikka',
    color: '#6987C9'
  },
  451: {
    imageUrl: luokittelemattomatImage,
    name: 'Fysiikka ja kemia sekä geo-, avaruus- ja tähtitiet',
    color: '#FFBF00'
  },
  499: {
    imageUrl: luokittelemattomatImage,
    name: 'Muu luonnontietteiden alan koulutus',
    color: '#D14081'
  },
  501: {
    imageUrl: luokittelemattomatImage,
    name: 'Arkkitehtuuri ja rakentaminen',
    color: '#23967F'
  },
  502: {
    imageUrl: luokittelemattomatImage,
    name: 'Kone-, metalli- ja energiatekniikka',
    color: '#6987C9'
  },
  506: {
    imageUrl: luokittelemattomatImage,
    name: 'Elintarvikeala ja biotekniikka',
    color: '#402E2A'
  },
  507: {
    imageUrl: luokittelemattomatImage,
    name: 'Prosessi-, kemian ja materiaalitekniikka',
    color: '#FFBF00'
  },
  599: {
    imageUrl: luokittelemattomatImage,
    name: 'Muu tekniikan ja liikenteen alan koulutus',
    color: '#23967F'
  },
  710: {
    imageUrl: luokittelemattomatImage,
    name: 'Kauneudenhoitoala',
    color: '#D14081'
  },
  999: {
    imageUrl: luokittelemattomatImage,
    name: 'Muu koulutus',
    color: '#402E2A'
  },
  751: {
    imageUrl: terveysImage,
    name: 'Terveysala ja hammashuolto',
    color: '#FFBF00'
  },
  753: {
    imageUrl: terveysImage,
    name: 'Farmasia ja muu lääkehuolto sekä tekniset terveysp',
    color: '#6987C9'
  },
  709: {
    imageUrl: terveysImage,
    name: 'eläinlääketiede',
    color: '#D14081'
  },
  203: {
    imageUrl: kirjallisuusImage,
    name: 'Kirjallisuus',
    color: '#402E2A'
  },
  3: {
    imageUrl: opetusImage,
    name: 'Lukiokoulutus',
    color: '#23967F'
  },
  51: {
    imageUrl: opetusImage,
    name: 'Oppimisvalmiuksien kehittäminen ja motivointi',
    color: '#6987C9'
  },
  103: {
    imageUrl: historiaImage,
    name: 'Historia ja arkeologia',
    color: '#FFBF00'
  },
  305: {
    imageUrl: sosiaaliImage,
    name: 'Sosiaalitieteet',
    color: '#D14081'
  },
  701: {
    imageUrl: sosiaaliImage,
    name: 'Sosiaaliala',
    color: '#23967F'
  },
  799: {
    imageUrl: sosiaaliImage,
    name: 'Muu sosiaali-, terveys- ja liikunta-alan koulutus',
    color: '#402E2A'
  },
  503: {
    imageUrl: tietoImage,
    name: 'Sähkö- ja automaatiotekniikka',
    color: '#FFBF00'
  },
  504: {
    imageUrl: tietoImage,
    name: 'Tieto- ja tietoliikennetekniikka',
    color: '#6987C9'
  },
  602: {
    imageUrl: puutarhaImage,
    name: 'Puutarhatalous ja puutarhanhoito',
    color: '#23967F'
  },
  851: {
    imageUrl: kotitalousImage,
    name: 'Kotitalous- ja kuluttajapalvelut',
    color: '#D14081'
  },
  99: {
    imageUrl: yleissivistäväImage,
    name: 'Muu yleissivistävä koulutus',
    color: '#402E2A'
  },
  207: {
    imageUrl: kulttuuriImage,
    name: 'Kulttuurin- ja taiteiden tutkimus',
    color: '#6987C9'
  },
  299: {
    imageUrl: kulttuuriImage,
    name: 'Muu kulttuurialan koulutus',
    color: '#FFBF00'
  },
  2: {
    imageUrl: perusopetusImage,
    name: 'Perusopetus',
    color: '#23967F'
  },
  104: {
    imageUrl: yhteiskunnallinenImage,
    name: 'Filosofia',
    color: '#D14081'
  },
  107: {
    imageUrl: yhteiskunnallinenImage,
    name: 'Teologia',
    color: '#402E2A'
  },
  30399: {
    imageUrl: yhteiskunnallinenImage,
    name: 'Muu hallinnon alan koulutus',
    color: '#6987C9'
  },
  306: {
    imageUrl: yhteiskunnallinenImage,
    name: 'Politiikka ja politiikkatieteet',
    color: '#23967F'
  },
  307: {
    imageUrl: yhteiskunnallinenImage,
    name: 'Oikeuskäytäntö ja oikeustieteet',
    color: '#FFBF00'
  },
  399: {
    imageUrl: yhteiskunnallinenImage,
    name: 'Muu yhteiskunnallisten aineiden, liiketalouden ja',
    color: '#D14081'
  },
  302: {
    imageUrl: yhteiskunnallinenImage,
    name: 'Kansantalous',
    color: '#23967F'
  },
  452: {
    imageUrl: luontoImage,
    name: 'Biologia ja maantiede',
    color: '#402E2A'
  },
  651: {
    imageUrl: luontoImage,
    name: 'Maatila- ja metsätalous',
    color: '#23967F'
  },
  603: {
    imageUrl: luontoImage,
    name: 'Kalatalous ja kalastus',
    color: '#6987C9'
  },
  605: {
    imageUrl: luontoImage,
    name: 'Luonto- ja ympäristöala',
    color: '#FFBF00'
  },
  699: {
    imageUrl: luontoImage,
    name: 'Muu luonnonvara- ja ympäristöalan koulutus',
    color: '#D14081'
  },
  509: {
    imageUrl: ajoneuvoImage,
    name: 'Ajoneuvo- ja kuljetusala',
    color: '#402E2A'
  },
  151: {
    imageUrl: humanistinenImage,
    name: 'Opetus- ja kasvatustyö ja psykologia',
    color: '#D14081'
  },
  199: {
    imageUrl: humanistinenImage,
    name: 'Muu humanistisen ja kasvatusalan koulutus',
    color: '#FFBF00'
  },
  101: {
    imageUrl: vapaaaikaImage,
    name: 'Vapaa-aika- ja nuorisotyö',
    color: '#6987C9'
  },
  951: {
    imageUrl: poliisiImage,
    name: 'Poliisi- ja vartiointiala',
    color: '#6987C9'
  },
  901: {
    imageUrl: poliisiImage,
    name: 'Sotilas- ja rajavartioala',
    color: '#23967F'
  },
  902: {
    imageUrl: poliisiImage,
    name: 'Palo- ja pelastusala',
    color: '#D14081'
  },
  202: {
    imageUrl: viestintäImage,
    name: 'Viestintä- ja informaatioala',
    color: '#402E2A'
  },
  301: {
    imageUrl: liiketalousImage,
    name: 'Liiketalous ja kauppa',
    color: '#6987C9'
  },
  351: {
    imageUrl: liiketalousImage,
    name: 'Yrittäjyys ja yrittäjäkasvatus',
    color: '#D14081'
  },
  510: {
    imageUrl: liiketalousImage,
    name: 'Tuotantotalous',
    color: '54'
  },
  30301: {
    imageUrl: kansalaisImage,
    name: 'Kansalais- ja järjestötoiminta',
    color: '#FFBF00'
  },
  508: {
    imageUrl: tektiiliImage,
    name: 'Tekstiili- ja vaatetusala',
    color: '#D14081'
  }
};
