<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-mobile {
  width: 100%;
}

.search-button {
  text-align: left;
  height: 4rem;
  width: 25rem;

  @media (max-width: 740px) {
    width: 16rem;
  }

  @media (max-width: 425px) {
    padding: 1.5rem 2rem 1.5rem 2rem;
    width: 55%;
  }
}

.side-icon {
  display: flex;
  align-self: center;
}

.search-parameters {
  display: grid;
  grid-template-areas:
    'a a a d d'
    'b dot c d d';
  align-items: center;
  grid-column-gap: 0.5rem;
  @media (max-width: 425px) {
    grid-template-areas:
      'a a a '
      'b dot c';
    grid-row-gap: 0.1rem;
    grid-column-gap: 0.3rem;
    font-size: 0.8rem;
  }
}

.search-keyword {
  grid-area: a;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-location {
  grid-area: b;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-distance {
  grid-area: c;
}

.placeholder {
  color: grey;
}

.search-icon {
  grid-area: d;
  justify-self: end;
  /* TODO: inherit this from scss */
  color: $primary;
  @media (max-width: 425px) {
    display: none;
  }
}

.dot {
  border-radius: 50%;
  height: 5px;
  width: 5px;
  background: black;

  @media (max-width: 425px) {
    height: 3px;
    width: 3px;
  }
}

.search-fields {
  padding: 2rem;
  grid-area: dot;
  display: flex;
  flex-direction: column;
  align-items: center;

  .field {
    padding: 0.1rem;
    justify-self: center;
  }
}

form {
  display: flex;
}
</style>

<style lang="scss">
section.mobile-search .search-button > span {
  display: block;
  width: 100%;
  padding: 0px 8px;
}
</style>

<template>
  <section class="wrapper">
    <MobileHeader class="header-mobile" />
    <b-button
      class="search-button"
      @click="searchModalActive = true"
      rounded
      outline
      slot="trigger"
      tag="a"
    >
      <div class="search-parameters">
        <span class="search-keyword" v-if="keyword">{{ keyword }}</span>
        <span class="search-keyword placeholder" v-else>{{
          $t('searchHeader.searchForCourse')
        }}</span>
        <div class="dot"></div>
        <span class="search-location" v-if="location">{{ location }}</span>
        <span class="search-location placeholder" v-else>{{
          $t('searchHeader.location')
        }}</span>
        <span class="search-distance" v-if="location"
          >{{ newDistance }} km
        </span>
        <span class="search-distance placeholder" v-else>{{
          $t('searchHeader.distance')
        }}</span>
        <b-icon class="search-icon" icon="magnify" size="is-medium"> </b-icon>
      </div>
    </b-button>

    <b-modal v-model="searchModalActive" :width="640" scroll="keep">
      <div class="content notification">
        <form v-on:submit.prevent class="search-fields">
          <b-field>
            <b-input
              v-bind:placeholder="$t('searchHeader.searchForCourse')"
              v-model="newKeyword"
              type="search"
              icon-right="magnify"
            >
            </b-input>
          </b-field>

          <b-field>
            <b-autocomplete
              :placeholder="$t('searchHeader.location')"
              v-model="newLocation"
              type="search"
              :data="locationSuggestions"
              icon-right="map-marker-outline"
              clearable
            >
            </b-autocomplete>
          </b-field>

          <b-field grouped class="field-container">
            <b-dropdown v-model="newDistance">
              <b-input
                :disabled="newLocation === undefined || newLocation === ''"
                class="distance-search"
                type="button"
                slot="trigger"
                icon-right="arrow-expand"
                :value="
                  newLocation
                    ? newDistance + ' km'
                    : $t('searchHeader.distance')
                "
              >
              </b-input>

              <b-dropdown-item :value="10" aria-role="listitem">
                <span>10 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="25" aria-role="listitem">
                <span>25 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="50" aria-role="listitem">
                <span>50 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="100" aria-role="listitem">
                <span>100 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="250" aria-role="listitem">
                <span>250 km</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>

          <b-field>
            <b-button
              @click="search"
              native-type="submit"
              type="is-primary"
              class="is-uppercase"
              >{{ $t('searchHeader.search') }}</b-button
            >
          </b-field>
        </form>
      </div>
    </b-modal>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import MobileHeader from '../../header/header-mobile.vue';
import { router } from '../../../router';
import { getLocationSuggestions } from '../../../utils/location-utils';
import useRouter from '../../../hooks/useRouter';

export default defineComponent({
  name: 'SearchHeader',
  components: {
    MobileHeader
  },
  props: {
    keyword: {
      type: String
    },
    distance: {
      type: Number
    },
    location: {
      type: String
    }
  },
  setup: (props) => {
    const { language } = router.currentRoute.params;
    const searchModalActive = ref(false);
    const menuModalActive = ref(false);
    const newKeyword = ref<string | undefined>(props.keyword);
    const newLocation = ref<string | undefined>(props.location);
    const newDistance = ref<number>(props.distance ?? 25);
    const locationSuggestions = computed(() =>
      getLocationSuggestions(newLocation.value)
    );
    const { pushRoute, goToFrontPage } = useRouter();

    watch(
      () => props.keyword,
      () => {
        newKeyword.value = props.keyword;
      }
    );

    watch(
      () => props.location,
      () => {
        newLocation.value = props.location;
      }
    );

    watch(
      () => props.distance,
      () => {
        newDistance.value = props.distance ?? 25;
      }
    );

    const search = async () => {
      searchModalActive.value = false;
      pushRoute({
        keyword: newKeyword.value,
        location: newLocation.value,
        distance: newLocation.value && newDistance.value.toString()
      });
    };

    return {
      language,
      search,
      newKeyword,
      newLocation,
      newDistance,
      locationSuggestions,
      searchModalActive,
      menuModalActive,
      goToFrontPage
    };
  }
});
</script>
