<style lang="scss" scoped>
.logo-image {
  width: 100%;
  align-self: center;
}
.logo-link {
  flex: auto;
}
</style>

<template :class="{ eopisto: serviceName === 'E-opisto.fi' }">
  <b-navbar-item
    tag="router-link"
    :to="{ path: '/' + language }"
    :aria-label="$t('logo.frontPage')"
    class="logo-link"
  >
    <img
      v-if="serviceName !== 'E-opisto.fi'"
      src="../../../frontend-assets/logo.svg"
      alt="Linnunrata.fi"
      :style="'maxWidth: ' + width"
      class="logo-image"
    />
    <img
      v-if="serviceName === 'E-opisto.fi'"
      src="../../../frontend-assets/logo.jpg"
      alt="Linnunrata.fi"
      :style="'maxWidth: ' + width + '; maxHeight: 100%'"
      class="logo-image"
    />
  </b-navbar-item>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { router } from '../../router';
import { translate } from '../../utils/misc-utils';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Logo',
  setup: (props, ctx) => {
    const { language } = router.currentRoute.params;
    return { language, serviceName: translate(ctx, 'footer.serviceName') };
  },
  props: {
    width: {
      type: String,
      default: '150px'
    }
  }
});
</script>
