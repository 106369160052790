<style scoped>
.word-search {
  width: 22rem;
}
.location-search {
  width: 18rem;
}
.distance-search {
  min-width: 11rem;
}

.search-button {
  margin-top: 31.5px;
}

.loading-overlay {
  height: calc(100% + 2rem);
}

.note {
  margin: 2rem;
  text-align: center;
}

@media all and (max-width: 1023px) {
  .container {
    width: 100%;
  }
  .word-search,
  .popular-words,
  .location-search input,
  .distance-search,
  .search-tooltip {
    width: 100%;
  }
  .lines-container {
    width: 100%;
    margin-right: 0 !important;
  }
  .field-container {
    margin-right: 0 !important;
    position: relative;
    width: 100%;
    padding: 0.3rem 10rem;
  }
}

@media all and (max-width: 800px) {
  .field-container {
    padding: 0.3rem 6rem;
  }
}

@media all and (max-width: 550px) {
  .field-container {
    padding: 0.3rem 2rem;
  }
}
</style>

<template>
  <section>
    <div class="container">
      <b-field
        position="is-centered"
        grouped
        group-multiline
        class="lines-container"
      >
        <b-field :label="$t('landerSearch.keyword')" class="field-container">
          <b-autocomplete
            :placeholder="$t('landerSearch.searchForCourse')"
            v-model="keyword"
            ref="keywordElement"
            type="is-primary"
            icon-right="magnify"
            class="word-search"
            clearable
            :data="searchSuggestions"
            :open-on-focus="true"
          >
            <template #header>
              <strong v-if="searchSuggestions.length > 0">{{
                $t('landerSearch.commonSearches')
              }}</strong>
            </template>
          </b-autocomplete>
        </b-field>

        <b-field class="field-container location-search">
          <template slot="label">
            {{ $t('landerSearch.location') }}
            <a class="has-text-weight-medium" @click="getMyLocation">{{
              $t('landerSearch.useMyCurrentLocation')
            }}</a>
          </template>
          <b-autocomplete
            :placeholder="$t('landerSearch.address')"
            v-model="location"
            type="search"
            :data="locationSuggestions"
            icon-right="map-marker-outline"
            clearable
            @select="onLocationAutocompleteSelect"
          >
          </b-autocomplete>
        </b-field>

        <b-field grouped class="field-container">
          <b-field :label="$t('landerSearch.distance')" expanded>
            <b-dropdown v-model="distance">
              <b-input
                :disabled="location === undefined || location === ''"
                class="distance-search"
                type="button"
                slot="trigger"
                icon-right="arrow-expand"
                :value="
                  location ? distance + ' km' : $t('landerSearch.distance')
                "
              >
              </b-input>

              <b-dropdown-item :value="10" aria-role="listitem">
                <span>10 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="25" aria-role="listitem">
                <span>25 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="50" aria-role="listitem">
                <span>50 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="100" aria-role="listitem">
                <span>100 km</span>
              </b-dropdown-item>

              <b-dropdown-item :value="250" aria-role="listitem">
                <span>250 km</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
          <b-field>
            <b-button
              @click="search"
              type="is-primary"
              class="is-uppercase search-button"
              >{{ $t('landerSearch.search') }}</b-button
            >
          </b-field>
        </b-field>
      </b-field>
      <b-loading
        :is-full-page="false"
        v-model="locationLoading"
        :can-cancel="true"
      ></b-loading>
      <p class="note">
        <b-icon icon="lightbulb-on-outline" />
        {{ $t('landerSearch.onlineClasses') }}
      </p>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from '@vue/composition-api';
import { filter, isEmpty } from 'lodash/fp';

import { router } from '../router';
import {
  getAddressByCoordinates,
  getLocationSuggestions
} from '../utils/location-utils';
import { translate } from '../utils/misc-utils';

export default defineComponent({
  name: 'LanderSearch',
  setup: (props, ctx) => {
    const locationLoading = ref(false);
    const keyword = ref<string | undefined>(undefined);
    const keywordElement = ref<HTMLElement | undefined>(undefined);
    const location = ref<string | undefined>(undefined);
    const distance = ref<number>(25);
    const locationSuggestions = computed(() =>
      getLocationSuggestions(location.value)
    );
    const distanceLearning = computed(() =>
      location.value === undefined ? 'true' : undefined
    );

    const getMyLocation = () => {
      locationLoading.value = true;
      navigator.geolocation.getCurrentPosition(
        async (pos) => {
          const address = await getAddressByCoordinates({
            lat: pos.coords.latitude,
            lon: pos.coords.longitude
          });
          if (!address) {
            throw new Error('locating error');
          }
          location.value = `${address.city}, ${address.county}`;
          locationLoading.value = false;
          onLocationAutocompleteSelect();
          ctx.emit('locating-successful');
        },
        () => {
          locationLoading.value = false;
          ctx.emit('locating-error');
        },
        {
          timeout: 3000
        }
      );
    };

    const onLocationAutocompleteSelect = () => {
      if (isEmpty(keyword.value)) {
        keywordElement.value?.focus();
      }
    };

    const initialSearchSuggestions = [
      translate(ctx, 'landerSearch.programming'),
      translate(ctx, 'landerSearch.yoga'),
      translate(ctx, 'landerSearch.painting')
    ];

    const searchSuggestions = computed(() => {
      if (!keyword.value || isEmpty(keyword.value)) {
        return initialSearchSuggestions;
      }

      return filter(
        (suggestion) =>
          suggestion.toLowerCase().includes(keyword.value?.toLowerCase()),
        initialSearchSuggestions
      );
    });

    const search = () =>
      router.push({
        name: 'search',
        query: {
          q: keyword.value,
          location: location.value,
          distance: location.value ? distance.value.toString() : undefined,
          distancelearning: distanceLearning.value
        }
      });

    return {
      keyword,
      location,
      distance,
      getMyLocation,
      locationSuggestions,
      keywordElement,
      search,
      locationLoading,
      searchSuggestions,
      onLocationAutocompleteSelect,
      distanceLearning
    };
  }
});
</script>
