import { filter, isEmpty } from 'lodash/fp';

import { Geopoint } from '../api';
import municipalitiesData from '../../frontend-assets/kunnat.json';

const municipalities = municipalitiesData.map(
  (m) => `${m.Kunta}, ${m.Maakunta}`
);

const OPENSTREETMAP_NOMINATIM_BASEURL = 'https://nominatim.openstreetmap.org';

export const DEFAULT_ZOOM = 5;
export const DEFAULT_GEOPOINT: Geopoint = { lat: 65.3, lon: 26.770566 };
export const DISTANCE_AND_ZOOM_PAIRS = new Map([
  [undefined, DEFAULT_ZOOM],
  [10, 12],
  [25, 10],
  [50, 9],
  [100, 7.5],
  [250, 6]
]);

export interface Address {
  county: string;
  city: string;
}

/**
 * Get address for coordinates from openstreetmap nominatim service
 *
 * @param geoPoint coordinates to be translated to address
 * @returns
 */
export const getAddressByCoordinates = async (
  geoPoint: Geopoint
): Promise<Address | undefined> => {
  const query = new URLSearchParams({
    lat: String(geoPoint.lat),
    lon: String(geoPoint.lon),
    format: 'json'
  });

  const response = await fetch(
    `${OPENSTREETMAP_NOMINATIM_BASEURL}/reverse?${query.toString()}`
  );
  if (!response.ok) {
    return undefined;
  }

  const data = await response.json();
  if (
    !data.address.county ||
    isEmpty(data.address.county) ||
    !data.address.city ||
    isEmpty(data.address.city)
  ) {
    return undefined;
  }

  return {
    county: data.address.county,
    city: data.address.city
  };
};

/**
 * Get coordinates for address from openstreetmap nominatim service
 *
 * @param address
 * @returns GeoPoint if coordinates were found, undefined if not
 */
export const getCoordinatesByAddress = async (
  address: string
): Promise<Geopoint | undefined> => {
  const query = new URLSearchParams({
    q: `${address}, finland`,
    format: 'json'
  });

  const response = await fetch(
    `${OPENSTREETMAP_NOMINATIM_BASEURL}/search?${query.toString()}`
  );
  if (!response.ok) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any[] = await response.json();
  if (isEmpty(data) || data.length < 1) {
    return undefined;
  }

  const lat = parseFloat(data[0].lat);
  const lon = parseFloat(data[0].lon);

  if (isNaN(lat) || isNaN(lon)) {
    return undefined;
  }

  return { lat, lon };
};

/**
 * Get a list of municipalities that match given input
 *
 * For location autocomplete
 *
 * @param input typed input
 * @returns array of municipality names
 */
export const getLocationSuggestions = (input: string | undefined): string[] => {
  if (!input || isEmpty(input)) {
    return [];
  }

  return filter(
    (municipality) => municipality.toLowerCase().includes(input.toLowerCase()),
    municipalities
  );
};
