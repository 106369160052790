<template>
  <b-pagination
    :total="totalNumberOfCourses"
    v-model="currentPage"
    :per-page="perPage"
    :icon-prev="prevIcon"
    :icon-next="nextIcon"
    :range-before="currentPage <= 6 ? 6 : 1"
    :range-after="currentPage < 6 ? 6 - currentPage : 1"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    @change="pushPage"
  >
    <b-pagination-button
      slot-scope="props"
      :page="props.page"
      v-show="props.page.number - currentPage < 50"
    >
      {{ props.page.number }}
    </b-pagination-button>
  </b-pagination>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from '@vue/composition-api';
import useRouter from '../../hooks/useRouter';

export default defineComponent({
  name: 'SearchPagination',
  props: {
    total: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true
    }
  },
  setup: (props) => {
    const { pushRoute } = useRouter();
    const totalNumberOfCourses = ref<number>(props.total);
    const currentPage = ref<number>(props.page);
    const perPage = 12;
    const prevIcon = 'chevron-left';
    const nextIcon = 'chevron-right';

    watch(
      () => props.total,
      () => {
        totalNumberOfCourses.value = props.total - 1;
      }
    );

    watch(
      () => props.page,
      () => {
        currentPage.value = props.page;
      }
    );

    function pushPage(page: number) {
      pushRoute({ page: page.toString() });
    }

    return {
      totalNumberOfCourses,
      currentPage,
      perPage,
      prevIcon,
      nextIcon,
      pushPage
    };
  }
});
</script>
