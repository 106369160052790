<style lang="scss" scoped>
span {
  padding-bottom: 0.2rem;
}

.button {
  margin-top: 1rem !important;
}

.weekdays {
  padding-bottom: 8px !important;
  display: flex;
  align-items: flex-start;
}

.weekday {
  display: grid;
  grid-template-columns: 2.6rem auto;
}

.weekday-short {
  text-transform: uppercase;
}

.weekday-icon {
  margin: 0.3rem 0.3rem 0 0;
}

.availability {
  border-radius: 100px;
  height: 0.8rem;
  width: 0.8rem;
  background: #6ac873;
  margin-right: 0.5rem;
}

.availability.type--almostfull {
  background: #ecc562;
}

.availability.type--full {
  background: #d47076;
}

.availability.type--sparesAvailable {
  background: #ff9970;
}
.availability.type--registrationClosed {
  background: #d47076;
}

.availability-container {
  display: flex;
  align-items: center;
  line-height: 1rem;
  margin: 0.2rem 0 0.3rem 0;
}

.availability-container > span {
  padding-bottom: 0;
}

.weekday-times {
  align-items: center;
  display: flex;
}

.price {
  font-weight: bold;
  font-size: 2rem;
}

.registration-box-desktop > button {
  margin-top: 2rem;
}

.info-section {
  margin-top: 0.2rem;
}
</style>

<template>
  <div>
    <span v-if="price" class="price">
      {{ price | price }}
    </span>
    <span v-else class="price">{{ $t('registrationBox.noprice') }}</span>
    <span v-if="course.begins && course.ends">
      <b-icon class="icon" icon="calendar-today" size="is-small" />
      {{ course.begins | dateRange(course.ends) }}
    </span>
    <div class="weekdays">
      <b-icon
        class="icon left-margin weekday-icon"
        icon="calendar-clock"
        size="is-small"
      />
      <div>
        <div
          v-for="day in sortBy('weekday', formattedWeekdays)"
          :key="String(day.weekday)"
          class="weekday"
        >
          <span class="weekday-short" v-if="day.weekday">
            {{ $d(day.weekday, 'weekdayShort') }}
          </span>
          <span v-if="day.times.length === 1">
            {{ day.times[0].begins }}&ndash;{{ day.times[0].ends }}
          </span>
          <span v-if="day.times.length > 1">
            <b-collapse :open="false" position="is-bottom" aria-id="weekdays">
              <template #trigger="props">
                <a aria-controls="weekdays" class="is-primary weekday-times">
                  {{
                    props.open
                      ? $t('registrationBox.hidetimes')
                      : $t('registrationBox.severaltimes')
                  }}
                  <b-icon
                    :icon="props.open ? 'menu-up' : 'menu-down'"
                    class="is-primary"
                  ></b-icon>
                </a>
              </template>
              <p v-for="time in day.times" :key="time.begins + time.ends">
                {{ time.begins }}&ndash;{{ time.ends }}
              </p>
            </b-collapse>
          </span>
        </div>
      </div>
    </div>
    <b>
      {{ $t('registrationBox.availablePlaces') }}
    </b>
    <div class="availability-container">
      <div class="availability" :class="`type--${availability}`" />
      <span v-if="availability">
        {{ $t(`registrationBox.${availability}`) }}
      </span>
    </div>

    <span class="info-section" v-if="course.registrationbegins">
      <b>{{ $t('registrationBox.registrationPeriodStarts') }}</b>
      <br />
      {{ course.registrationbegins | dateTime }}
    </span>
    <span class="info-section" v-if="course.registrationendssoft">
      <b>{{ $t('registrationBox.registrationPeriodEnds') }}</b>
      <br />
      {{ course.registrationendssoft | midnight }}
    </span>
    <span
      class="info-section"
      v-if="!course.registrationbegins && !course.registrationendssoft"
    >
      <b>{{ $t('registrationBox.registrationPeriod') }}</b>
      <br />
      {{ $t('registrationBox.notprovided') }}
    </span>

    <b-button
      tag="a"
      class="is-primary button"
      :href="course.registrationlink"
      target="_blank"
      :disabled="!course.participantcount.registrationopen"
    >
      {{ $t('registrationBox.register') }}
    </b-button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { HellewiCoursePartial } from '@/frontend-src/api';
import { getDefaultPrice, getPriceEuros } from '../../utils/course-utils';
import { formatWeekdays, FormatedWeekday } from '../../utils/date-utils';
import { sortBy } from 'lodash/fp';
import { Availability, getAvailability } from '../../utils/availability-utils';

export default defineComponent({
  name: 'registration-box',
  props: {
    course: {
      type: Object as PropType<HellewiCoursePartial>
    }
  },
  setup(props) {
    const availability = computed<Availability | undefined>(() =>
      getAvailability(props.course?.participantcount)
    );

    const formattedWeekdays = computed<FormatedWeekday[]>(() =>
      formatWeekdays(props.course?.days)
    );

    const price = computed<number | undefined>(() =>
      getPriceEuros(getDefaultPrice(props.course))
    );

    return {
      availability,
      getDefaultPrice,
      getPriceEuros,
      sortBy,
      formattedWeekdays,
      price
    };
  }
});
</script>
