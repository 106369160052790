import VueRouter, { Route, RouteConfig } from 'vue-router';

import Course from './views/course.vue';
import Index from './views/index.vue';
import Language from './views/language.vue';
import NotFound from './views/not-found.vue';
import Search from './views/search.vue';
import ServiceProviders from './views/service-providers.vue';
import Vue from 'vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    // TODO: Redirect to a specific saved in Local or Session Storage?
    redirect: '/fi'
  },
  {
    path: '/:language',
    component: Language,
    props: true,
    children: [
      {
        path: '/',
        name: 'index',
        props: true,
        component: Index
      },
      {
        path: '/:language/search',
        name: 'search',
        props: true,
        component: Search
      },
      {
        path: '/:language/service-providers',
        name: 'service-providers',
        props: true,
        component: ServiceProviders
      },
      {
        path: '/:language/course/:id',
        name: 'course',
        props: true,
        component: Course
      },
      {
        path: '/:language/404',
        name: '404',
        props: true,
        component: NotFound
      },
      {
        path: '*',
        redirect: '404'
      }
    ]
  }
];

interface VueRouterExtended extends VueRouter {
  /**
   * Route where the user was previously.
   */
  previousRoute?: Route;
  /**
   * Go back to the previous page if there is a previous page
   * in our service, if not, then go to frontpage.
   */
  backOrFrontpage: () => void;
}

/**
 * Get a monkey-patched router with referer and function to
 * go back or to frontpage.
 *
 * @returns
 */
const getRouter = (): VueRouterExtended => {
  const r = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
      return { x: 0, y: 0 };
    }
  }) as VueRouterExtended;

  r.beforeEach((to, from, next) => {
    r.previousRoute = from;
    next();
  });

  r.backOrFrontpage = () => {
    // previousRoute might be there if there actually wasn't a previous
    // route, but if it is, its name is null
    if (r.previousRoute?.name) {
      r.back();
    } else if (r.currentRoute.params.language) {
      r.push({
        name: 'index',
        params: { language: r.currentRoute.params.language }
      });
    } else {
      r.push({ name: 'index' });
    }
  };

  return r;
};

export const router = getRouter();
