<style lang="scss" scoped>
.filter-item {
  white-space: normal !important;
}
@media (max-width: 1400px) {
  .button-group button {
    margin: 0.4rem;
  }
}
</style>

<template>
  <b-checkbox
    v-if="filter"
    :key="filter.inputId"
    :id="filter.inputId"
    :value="selected === 'true' ? 'true' : 'false'"
    :native-value="selected === 'true' ? 'true' : 'false'"
    @input="filterClicked(filter)"
  >
    {{ filter.translatelabel ? $t(`checkbox.${filter.name}`) : filter.name }}
  </b-checkbox>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  watch,
  PropType
} from '@vue/composition-api';
import { find, includes, map } from 'lodash/fp';

import useRouter from '../../hooks/useRouter';
import { translate } from '../../utils/misc-utils';
import { HellewiCatalogItem } from '@/frontend-src/api';

export interface FilterItemOption {
  id: string;
  name: string;
}

export default defineComponent({
  name: 'SearchFilterDropdown',
  props: {
    // initial selection, or selection that is got from route through props
    // this has to be array due to reasons, but use the first one if there
    // are values
    initialSelection: String as PropType<string>,
    filter: Object as PropType<HellewiCatalogItem>
  },
  setup: (props, context) => {
    const { pushRoute } = useRouter();

    const selected = ref<string | undefined>(
      props.initialSelection ? props.initialSelection : undefined
    );
    const previous = ref<string | undefined>(
      props.initialSelection ? props.initialSelection : undefined
    );

    const searchValue = ref<string>('');

    const filterKeyword = computed(() => {
      if (!props.filter?.keywords) {
        return '';
      }
      const kwData = props.filter.keywords[0].split(':');
      if (kwData[0] === 'tag') {
        return kwData[1];
      } else {
        return kwData[0];
      }
    });

    // when an option is clicked, check manually whether the selection should
    // be cleared as buefy doesn't have logic for that
    // (if clicked option was already selected, clear selection)
    const filterClicked = (filter: HellewiCatalogItem) => {
      if (!filter.keywords) {
        return;
      }
      if (previous.value === 'true') {
        selected.value = undefined;
      } else {
        selected.value = 'true';
      }
    };

    // update search through router and change page to first one,
    // save this selection as the previous one for filterClicked
    // and current selection name for selectedName
    watch(selected, (cur) => {
      if (filterKeyword.value && cur !== previous.value) {
        pushRoute({
          page: '1',
          [filterKeyword.value]: cur ? String(cur) : ''
        });
        previous.value = cur;
      }
      previous.value = cur;
    });

    // update search value when props change
    watch(
      () => props.initialSelection,
      () => {
        selected.value = props.initialSelection
          ? props.initialSelection
          : undefined;
      }
    );

    return {
      filterClicked,
      selected,
      searchValue
    };
  }
});
</script>
