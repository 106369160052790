<style lang="scss" scoped>
.desktop-map {
  position: sticky;
  height: 100vh;
  top: 0rem;
}

.tenant {
  max-width: 38rem;
  margin: 0 2rem 2.5rem 0;
}

.filter-container {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.pagination-container {
  padding-top: 1rem;
}

.search-content {
  position: relative;
  width: 100%;
  .aside-content {
    padding: 1rem !important;
  }
}

.mobile-total {
  display: block;
  text-align: center;
  margin-bottom: 0.2rem;
}

main {
  padding-left: 1.6rem;
  margin-bottom: 0;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 0;
}

.show-map-button {
  display: none;
}

@media (max-width: 1250px) {
  main {
    display: flex;
    flex-direction: column-reverse;
    justify-items: center;
    align-items: center;
    padding: 0;
  }
  .desktop-map {
    display: none;
  }
  .show-map-button {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    text-align: center;
  }

  .map-icon {
    color: $primary;
    padding: 2rem;
  }

  .mobile-map {
    height: 75vh;
    width: 100%;
  }

  .desktop-search {
    display: none;
  }
  .mobile-search {
    display: relative;
  }
  .aside-content {
    width: 40rem;
  }
  .tenant {
    margin: 0 0 2rem 0;
  }
}

@media (min-width: 1251px) {
  .desktop-search {
    display: relative;
  }
  .mobile-search {
    display: none;
    align-self: center;
  }
  .mobile-total {
    display: none;
  }
  .top-gap {
    display: none;
  }
}

@media (min-width: 1450px) {
  .tenant {
    max-width: 58rem;
  }
}

@media (max-width: 740px) {
  .aside-content {
    width: auto;
  }
  .tenant {
    margin: 0 1rem 2rem 1rem;
  }
}
</style>

<template>
  <div :class="{ eopisto: serviceName === 'E-opisto.fi' }">
    <SearchHeaderDesktop
      class="desktop-search"
      :keyword="keyword"
      :location="location"
      :distance="distance || undefined"
    />
    <SearchHeaderMobile
      class="mobile-search"
      :keyword="keyword"
      :location="location"
      :distance="distance || undefined"
    />

    <main class="columns search-content is-gapless">
      <aside class="cards-container aside-content">
        <div class="desktop-search">
          <span class="is-uppercase is-size-7">
            <span>
              {{
                $tc(`search.amountOfSearchResults`, courseCount, {
                  n: courseCount > 9990 ? $t('search.over10000') : courseCount
                })
              }}
            </span>
            <span v-if="location && distance">
              · {{ $tc('search.area', distance) }}
            </span>
          </span>
          <h1 v-if="location" class="has-text-weight-light mt-0">
            {{ $t('search.offeringInArea', { location }) }}
          </h1>
        </div>

        <SearchFilters
          class="filter-container"
          :catalog="catalog"
          :classifications="classifications"
          :distanceLearning="distanceLearning"
          :languages="languages"
          :semester="semester"
          :serviceProviders="serviceProviders"
          :weekdays="weekdays"
          :registrationOpen="registrationOpen"
          :civicSkills="civicSkills"
          :creditCourse="creditCourse"
        />

        <div v-if="serviceProviders" class="tenant-container">
          <TenantDropdown
            :tenant="tenant"
            :browseLink="false"
            :detailsText="true"
            class="tenant"
          />
        </div>

        <span class="is-uppercase is-size-7 mobile-total">
          {{
            $tc(`search.amountOfSearchResults`, courseCount, {
              n: courseCount > 9990 ? $t('search.over10000') : courseCount
            })
          }}
        </span>

        <b-button @click="showMap = !showMap" rounded class="show-map-button">
          <span>{{ $t('search.showMap') }}</span>
        </b-button>

        <CourseList :courses="courses" :isLoading="isLoading" />
        <div class="pagination-container" v-if="courses.length > 0">
          <SearchPagination :total="courseCount" :page="page" />
        </div>
      </aside>

      <section class="column desktop-map">
        <SearchMap
          :coordinates="coordinates"
          :zoom="zoom"
          :locations="locations"
          :courses="courses"
          :isLoading="isLoading"
        />
      </section>

      <b-modal v-model="showMap">
        <SearchMap
          :key="showMap"
          :showMap="showMap"
          class="mobile-map"
          :coordinates="coordinates"
          :zoom="zoom"
          :locations="locations"
          :courses="courses"
          :isLoading="isLoading"
        />
      </b-modal>
    </main>
    <Footer />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from '@vue/composition-api';

import { useGetCatalog } from '../hooks/useCatalogApi';
import { useListCourses } from '../hooks/useCourseApi';
import useSearch from '../hooks/useSearch';
import { useListTenants } from '../hooks/useTenantApi';
import {
  RequestState,
  stateHasError,
  stateIsLoading,
  useErrorToast
} from '../utils/api-utils';

import CourseList from '../components/course-list/course-list.vue';
import Footer from '../components/footer.vue';
import SearchFilters from '../components/search/search-filters.vue';
import SearchHeaderDesktop from '../components/search/search-header/search-header-desktop.vue';
import SearchHeaderMobile from '../components/search/search-header/search-header-mobile.vue';
import SearchMap from '../components/search/search-map.vue';
import SearchPagination from '../components/search/search-pagination.vue';
import TenantDropdown from '../components/service-providers/tenant-dropdown.vue';

import { translate } from '../utils/misc-utils';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Search',
  components: {
    CourseList,
    Footer,
    SearchFilters,
    SearchHeaderDesktop,
    SearchHeaderMobile,
    SearchMap,
    SearchPagination,
    TenantDropdown
  },
  setup: (props, ctx) => {
    const COURSES_ON_PAGE = 12;

    /**
     * API
     */

    const { response: catalog, execute: getCatalog } = useGetCatalog();
    const { response: tenants, execute: listTenants } = useListTenants();
    const {
      response: listCoursesResponse,
      execute: listCourses,
      state: listCoursesState
    } = useListCourses();

    const listCoursesIsLoading = stateIsLoading(listCoursesState);
    const listCoursesHasError = stateHasError(listCoursesState);
    const courses = computed(() => listCoursesResponse.value.courses);
    const courseCount = computed(() => listCoursesResponse.value.count);
    const locations = computed(() => listCoursesResponse.value.locations);

    const {
      classifications,
      civicSkills,
      creditCourse,
      coordinates,
      distance,
      distanceLearning,
      geocodingState,
      keyword,
      languages,
      location,
      page,
      q,
      registrationOpen,
      semester,
      serviceProviders,
      weekdays,
      zoom
    } = useSearch(ctx);

    const geocodingIsLoading = stateIsLoading(geocodingState);
    const geocodingHasError = stateHasError(geocodingState);

    const isLoading = computed(
      () => geocodingIsLoading.value || listCoursesIsLoading.value
    );

    /**
     * HELPERS FOR TEMPLATE
     */

    const showMap = ref(false);

    const tenant = computed(() =>
      tenants.value?.find((te) => te.tenant === serviceProviders.value?.[0])
    );

    /**
     * REACTIVE LOGIC
     */

    onMounted(() => {
      listTenants();
      getCatalog(q.value);
      listCourses({ q: q.value, page: page.value, limit: COURSES_ON_PAGE });
    });

    watch(q, getCatalog);
    watch([q, page], () => {
      listCourses({ q: q.value, page: page.value, limit: COURSES_ON_PAGE });
    });

    /**
     * ERROR HANDLING
     */

    const { warnToast, clearErrorToasts } = useErrorToast(ctx);

    // display locating error toast to user if the location search
    // failed (probably due to garbage in the location search input)
    watch(geocodingHasError, (hasErrorNow, hadErrorBefore) => {
      if (hasErrorNow) {
        warnToast('landerSearch.locatingError');
      } else if (hadErrorBefore) {
        clearErrorToasts();
      }
    });

    // display a different error if any of the other requests failed
    // as this probably means an error in the backend connection
    watch(listCoursesHasError, (hasErrorNow, hadErrorBefore) => {
      if (hasErrorNow) {
        warnToast('search.loadingError');
      } else if (hadErrorBefore) {
        clearErrorToasts();
      }
    });

    onBeforeUnmount(clearErrorToasts);

    return {
      catalog,
      civicSkills,
      classifications,
      coordinates,
      courseCount,
      courses,
      creditCourse,
      distance,
      distanceLearning,
      isLoading,
      keyword,
      languages,
      location,
      locations,
      page,
      q,
      registrationOpen,
      RequestState,
      semester,
      serviceProviders,
      showMap,
      tenant,
      weekdays,
      zoom,
      serviceName: translate(ctx, 'footer.serviceName')
    };
  }
});
</script>
