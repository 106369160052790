import { ref } from '@vue/composition-api';
import { map, memoize } from 'lodash/fp';

import {
  Configuration,
  HellewiTenant,
  TenantApi,
  TenantApiInterface
} from '../api';

import {
  Api,
  ApiEndpoint,
  ApiEndpointInitialization,
  RequestState
} from '../utils/api-utils';

/**
 * Use tenant API
 *
 * This is a singleton function that will return always the same
 * variable references that are returned on the first call.
 * (accomplished via memoize)
 */
export const useTenantApi: Api<TenantApiInterface> = memoize(() => {
  const api = ref<TenantApiInterface | undefined>(undefined);

  const changeConfiguration = (configuration: Configuration) => {
    api.value = new TenantApi(configuration);
  };

  return {
    api,
    changeConfiguration
  };
});

const formatUrl = (url: string | undefined): string | undefined => {
  if (!url) {
    return undefined;
  } else if (url.includes('://')) {
    return url;
  } else {
    return `https://${url}`;
  }
};

export const useListTenants: ApiEndpoint<void, HellewiTenant[]> = memoize(
  () => {
    const initial: HellewiTenant[] = [];
    const { api } = useTenantApi();
    const state = ref<RequestState>(RequestState.Uninitialized);
    const response = ref<HellewiTenant[]>(initial);

    ApiEndpointInitialization(api, state, response, initial);

    const execute = async () => {
      if (
        !api.value ||
        state.value === RequestState.Uninitialized ||
        state.value === RequestState.Loading ||
        // don't load again if this is already successfully loaded
        state.value === RequestState.Success
      ) {
        return;
      }

      try {
        state.value = RequestState.Loading;
        response.value = map(
          (tenant) => ({
            ...tenant,
            facebook: formatUrl(tenant.facebook),
            twitter: formatUrl(tenant.twitter),
            instagram: formatUrl(tenant.instagram),
            linkedin: formatUrl(tenant.linkedin),
            homepage: formatUrl(tenant.homepage)
          }),
          await api.value.listTenants()
        );
        state.value = RequestState.Success;
      } catch {
        response.value = initial;
        state.value = RequestState.Error;
      }
    };

    return {
      initial,
      state,
      response,
      execute
    };
  }
);
