<style lang="scss" scoped>
.language-columns {
  display: flex;
  align-content: center;
}

@media all and (min-width: 1024px) {
  .mobile {
    display: none;
  }
}
@media all and (max-width: 1023px) {
  .language-columns :first-child {
    margin-right: 0.2rem;
  }
  .mobile {
    display: inline-block;
  }
}
</style>

<template>
  <b-navbar-dropdown right collapsible>
    <template slot="label">
      <div class="language-columns">
        <b-icon icon="earth"></b-icon>
        <span class="mobile">{{ $t('header.languages') }}</span>
      </div>
    </template>
    <b-navbar-item v-on:click="changeLanguage('fi')"> Suomeksi </b-navbar-item>
    <b-navbar-item v-on:click="changeLanguage('sv')">
      På svenska
    </b-navbar-item>
    <b-navbar-item v-on:click="changeLanguage('en')">
      In English
    </b-navbar-item>
  </b-navbar-dropdown>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import { changeLanguage } from '../../i18n';

export default defineComponent({
  name: 'LanguageSelection',
  setup: () => {
    return { changeLanguage };
  }
});
</script>
