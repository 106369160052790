import { currentYear, nextYear } from './date-utils';

interface Params {
  keyword?: string | undefined;
  coords?:
    | {
        lat: number;
        lon: number;
      }
    | undefined;
  distance?: number | undefined;
  weekdays?: string[] | undefined;
  semester?: Semester | undefined;
  categories?: string[] | undefined;
  classifications?: string[] | undefined;
  serviceProviders?: string[] | undefined;
  distanceLearning?: string | undefined;
  registrationOpen?: string | undefined;
  civicSkills?: string | undefined;
  creditCourse?: string | undefined;
}

export const generateQuery = (params: Params) => {
  const {
    keyword,
    coords,
    distance,
    weekdays,
    semester,
    classifications,
    serviceProviders,
    distanceLearning,
    registrationOpen,
    civicSkills,
    creditCourse
  } = params;

  let query = '';
  if (keyword) {
    query = `${keyword} `;
  }
  if (distance && coords) {
    query += `distancefrom:${coords.lat},${coords.lon} distancesoft:<${distance}km distancehard:<${distance}km `;
  }

  if (weekdays) {
    for (const weekday of weekdays) {
      query += `weekday:${weekday} `;
    }
  }

  if (serviceProviders) {
    for (const serviceProvider of serviceProviders) {
      query += `tenant:${serviceProvider} `;
    }
  }

  if (classifications) {
    for (const classification of classifications) {
      query += `classification:${classification} `;
    }
  }

  if (semester) {
    query += `${dateRangeForSemester[semester]} `;
  }

  if (registrationOpen === 'true') {
    query += 'registrationopen:true ';
  }
  if (civicSkills === 'true') {
    query += 'tag:civicskills ';
  }
  if (creditCourse === 'true') {
    query += 'tag:creditcourse ';
  }

  if (distanceLearning === 'true') {
    query += 'location:distancelearning ';
  } else if (distanceLearning === 'false') {
    query += 'location:!distancelearning ';
  }

  query = query.trimEnd();

  return query === '' ? undefined : query;
};

export enum Semester {
  CurrentYearAutumn = 'currentYearAutumn',
  CurrentYearSpring = 'currentYearSpring',
  CurrentYearSummer = 'currentYearSummer',
  NextYearAutumn = 'nextYearAutumn',
  NextYearSpring = 'nextYearSpring',
  NextYearSummer = 'nextYearSummer'
}

export enum DistanceLearning {
  True = 'true',
  False = 'false'
}

/**
 * Explanation in search-filters.vue where options are defined
 */
const dateRangeForSemester: { [key in Semester]: string } = {
  [Semester.CurrentYearAutumn]: `begins:>=${currentYear}-08-01 ends:<=${currentYear}-12-31`,
  [Semester.CurrentYearSpring]: `begins:>=${currentYear}-01-01 ends:<=${currentYear}-07-31`,
  [Semester.CurrentYearSummer]: `begins:>=${currentYear}-06-01 ends:<=${currentYear}-08-31`,
  [Semester.NextYearAutumn]: `begins:>=${nextYear}-08-01 ends:<=${nextYear}-12-31`,
  [Semester.NextYearSpring]: `begins:>=${nextYear}-01-01 ends:<=${nextYear}-07-31`,
  [Semester.NextYearSummer]: `begins:>=${nextYear}-06-01 ends:<=${nextYear}-08-31`
};

export function isNumeric(value: string) {
  return /^\d+$/.test(value);
}
