import { render, staticRenderFns } from "./lander-categories.vue?vue&type=template&id=36792a28&scoped=true"
import script from "./lander-categories.vue?vue&type=script&lang=ts"
export * from "./lander-categories.vue?vue&type=script&lang=ts"
import style0 from "./lander-categories.vue?vue&type=style&index=0&id=36792a28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36792a28",
  null
  
)

export default component.exports