<style lang="scss" scoped>
.mobile-tenant {
  border-radius: 10px;
  margin: 0.5rem 0;
}

.button-style {
  text-transform: uppercase;
  margin-left: 2rem;
}

.browse-style {
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 1rem;
}

.card-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -1rem -1rem 0 -1rem;
}

.icons {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.icon-large {
  color: #b3b3b3;
  margin: 0.3rem 0 0 1rem;
}

.icon-small {
  color: #999999;
  margin-right: 0.5rem;
}

.search {
  width: 60%;
  margin: 50px auto;
}

.links-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 1rem 0 -2rem;
}

.info-logo-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.info-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 1rem 1rem 0 0;
}

.info {
  margin-left: 1rem;
  display: flex;
  align-items: baseline;
  word-break: break-word;
}

.location-info {
  display: flex;
  flex-direction: column;
}

.button-style {
  display: none;
}

.logo {
  object-fit: contain;
  max-width: 100%;
  height: 3rem;
  margin: 1rem 0 0 1rem;
}

@media (max-width: 505px) {
  .browse-style {
    display: none;
  }
  .links-wrapper {
    flex-direction: column;
    margin: 1rem 0 0 0;
    width: 100%;
  }
  .button-style {
    display: block;
    width: calc(100% - 2rem);
    margin: 1rem 1rem 0 1rem;
  }
  .router-style {
    width: 100%;
  }
  .icon-large {
    margin: 0;
  }
  .icons {
    width: calc(100% - 2rem);
    margin: 0 1rem 0 1rem;
    display: grid;
    grid-template-columns: repeat(5, 2rem);
    justify-content: space-between;
  }
  .search {
    margin: 20px 0;
    width: 100%;
  }
  .details {
    display: none;
  }
}
</style>

<template>
  <b-collapse
    v-if="tenant"
    class="card mobile-tenant"
    animation="slide"
    :open="false"
    :aria-id="tenant.tenant"
  >
    <div
      slot="trigger"
      slot-scope="props"
      class="card-header"
      role="button"
      :aria-controls="tenant.tenant"
    >
      <p class="card-header-title">
        {{ tenant.name }}
      </p>
      <a class="card-header-icon">
        <span class="details" v-if="detailsText">{{
          $t('tenantDropdown.details')
        }}</span>
        <b-icon :icon="props.open ? 'menu-up' : 'menu-down'"> </b-icon>
      </a>
    </div>
    <div class="card-content">
      <div class="info-logo-wrapper">
        <div v-if="tenant.logo">
          <img class="logo" alt="logo" :src="tenant.logo" />
        </div>
        <div class="info-wrapper">
          <div class="info" v-if="tenant.location">
            <b-icon class="icon-small" icon="map-marker" size="is-small" />
            <div class="location-info">
              <span v-if="tenant.location.address">
                {{ tenant.location.address }}
              </span>
              <span>
                {{
                  [tenant.location.postalcode, tenant.location.city]
                    .filter(Boolean)
                    .join(' ')
                }}
              </span>
            </div>
          </div>
          <div class="info" v-if="tenant.phone">
            <b-icon class="icon-small" icon="phone" size="is-small" />
            <a v-if="phone" :href="phone">{{ tenant.phone }}</a>
            <span v-else>{{ tenant.phone }}</span>
          </div>
          <div class="info" v-if="tenant.email">
            <b-icon class="icon-small" icon="email" size="is-small" />
            <a :href="email">
              {{ tenant.email }}
            </a>
          </div>
          <div class="info" v-if="tenant.homepage">
            <b-icon class="icon-small" icon="home" size="is-small" />
            <a :href="tenant.homepage" target="_blank">
              {{ tenant.homepage }}
            </a>
          </div>
        </div>
      </div>
      <div class="links-wrapper">
        <div class="icons">
          <a v-if="tenant.facebook" :href="tenant.facebook" target="_blank">
            <b-icon class="icon-large" icon="facebook" size="is-medium" />
          </a>
          <a v-if="tenant.instagram" :href="tenant.instagram" target="_blank">
            <b-icon class="icon-large" icon="instagram" size="is-medium" />
          </a>
          <a v-if="tenant.linkedin" :href="tenant.linkedin" target="_blank">
            <b-icon class="icon-large" icon="linkedin" size="is-medium" />
          </a>
          <a v-if="tenant.twitter" :href="tenant.twitter" target="_blank">
            <b-icon class="icon-large" icon="twitter" size="is-medium" />
          </a>
        </div>
        <router-link
          v-if="browseLink"
          class="router-style"
          :to="{
            path: `/${language}/search?serviceproviders=${tenant.tenant}`
          }"
        >
          <b-button class="is-primary button-style">
            {{ $t('serviceProviders.browse') }}
          </b-button>
          <span class="is-primary browse-style">
            {{ $t('serviceProviders.browse') }}
          </span>
        </router-link>
      </div>
    </div>
  </b-collapse>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { HellewiTenant } from '@/frontend-src/api';
import { router } from '../../router';

export default defineComponent({
  name: 'TenantDropdown',
  props: {
    tenant: {
      type: Object as PropType<HellewiTenant>
    },
    browseLink: {
      type: Boolean
    },
    detailsText: {
      type: Boolean
    }
  },
  setup: (props) => {
    const { language } = router.currentRoute.params;

    const email = computed(() => 'mailto:' + props.tenant?.email);
    const phone = computed(() =>
      props.tenant?.phone && props.tenant.phone.match(/^\+?[0-9 ()-]+$/)
        ? `tel:${props.tenant.phone.replace(/[ ()-]/g, '')}`
        : undefined
    );

    return {
      language,
      email,
      phone
    };
  }
});
</script>
