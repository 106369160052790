import 'leaflet/dist/leaflet.css';

import {
  formatDate,
  formatDateRange,
  formatDateTime,
  formatDateTimeRange,
  formatMidnight,
  formatPrice,
  formatTime,
  formatTimeRange
} from './filters';

import App from './App.vue';
import Buefy from 'buefy';
import Meta from 'vue-meta';
import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import VueI18n from 'vue-i18n';
import { capitalize } from 'lodash/fp';
import { initializeI18n } from './i18n';
import { router } from './router';

Vue.use(Buefy);
Vue.use(Meta);
Vue.use(VueCompositionApi);
Vue.use(VueI18n);

Vue.config.productionTip = false;

Vue.filter('capitalize', capitalize);
Vue.filter('date', formatDate);
Vue.filter('dateRange', formatDateRange);
Vue.filter('dateTime', formatDateTime);
Vue.filter('dateTimeRange', formatDateTimeRange);
Vue.filter('time', formatTime);
Vue.filter('timeRange', formatTimeRange);
Vue.filter('price', formatPrice);
Vue.filter('midnight', formatMidnight);

new Vue({
  i18n: initializeI18n(),
  router,
  render: (h) => h(App)
}).$mount('#app');
