import { SetupContext, ref, watch } from '@vue/composition-api';
import { includes } from 'lodash/fp';

import { Geopoint } from '../api';
import { RequestState } from '../utils/api-utils';
import {
  DEFAULT_GEOPOINT,
  DEFAULT_ZOOM,
  DISTANCE_AND_ZOOM_PAIRS,
  getCoordinatesByAddress
} from '../utils/location-utils';
import { generateQuery, isNumeric, Semester } from '../utils/query-utils';

const useSearch = (ctx: SetupContext) => {
  const geocodingState = ref<RequestState>(RequestState.Initialized);
  const coordinates = ref<Geopoint>(DEFAULT_GEOPOINT);
  const zoom = ref<number | undefined>(DEFAULT_ZOOM);
  const keyword = ref<string>();
  const location = ref<string>();
  const distance = ref<number>();
  const weekdays = ref<string[]>();
  const semester = ref<Semester>();
  const classifications = ref<string[]>();
  const languages = ref<string[]>();
  const serviceProviders = ref<string[]>();
  const distanceLearning = ref<string>();
  const registrationOpen = ref<string>();
  const civicSkills = ref<string>();
  const creditCourse = ref<string>();

  const page = ref<number>(1);
  const q = ref<string | undefined>(undefined);

  watch(
    () => ctx.root.$route,
    async (route) => {
      page.value = isNumeric(route.query.page as string)
        ? parseInt(route.query.page as string, 10)
        : 1;

      keyword.value = route.query.q ? route.query.q.toString() : '';

      weekdays.value = route.query.weekdays
        ? (route.query.weekdays as string).split(',')
        : undefined;

      semester.value =
        route.query.semester && includes(route.query.semester, Semester)
          ? (route.query.semester as Semester)
          : undefined;

      classifications.value = route.query.classifications
        ? (route.query.classifications as string).split(',')
        : undefined;

      languages.value = route.query.languages
        ? (route.query.languages as string).split(',')
        : undefined;

      serviceProviders.value = route.query.serviceproviders
        ? (route.query.serviceproviders as string).split(',')
        : undefined;

      distanceLearning.value = route.query.distancelearning
        ? (route.query.distancelearning as string)
        : undefined;

      registrationOpen.value =
        route.query.registrationopen && route.query.registrationopen === 'true'
          ? 'true'
          : undefined;
      civicSkills.value =
        route.query.civicskills && route.query.civicskills === 'true'
          ? 'true'
          : undefined;
      creditCourse.value =
        route.query.creditcourse && route.query.creditcourse === 'true'
          ? 'true'
          : undefined;

      if (route.query.location) {
        geocodingState.value = RequestState.Loading;
        location.value = route.query.location.toString();
        try {
          const coords = await getCoordinatesByAddress(location.value);

          if (coords) {
            geocodingState.value = RequestState.Success;
            coordinates.value = { lat: coords.lat, lon: coords.lon };

            if (route.query.distance) {
              distance.value = Number(route.query.distance);
              zoom.value = route.query.distance
                ? DISTANCE_AND_ZOOM_PAIRS.get(Number(route.query.distance))
                : DEFAULT_ZOOM;
            }
          } else {
            geocodingState.value = RequestState.Error;
          }
        } catch (e) {
          geocodingState.value = RequestState.Error;
        }
      } else {
        geocodingState.value = RequestState.Initialized;
        location.value = undefined;
        distance.value = undefined;
        coordinates.value = DEFAULT_GEOPOINT;
        zoom.value = DEFAULT_ZOOM;
      }

      if (geocodingState.value !== RequestState.Error) {
        q.value = generateQuery({
          keyword: keyword.value,
          coords: location.value
            ? { lat: coordinates.value.lat, lon: coordinates.value.lon }
            : undefined,
          distance: distance.value,
          weekdays: weekdays.value,
          semester: semester.value,
          classifications: classifications.value,
          serviceProviders: serviceProviders.value,
          distanceLearning: distanceLearning.value,
          registrationOpen: registrationOpen.value,
          civicSkills: civicSkills.value,
          creditCourse: creditCourse.value
        });
      }
    },
    { immediate: true }
  );

  return {
    classifications,
    civicSkills,
    coordinates,
    creditCourse,
    distance,
    distanceLearning,
    geocodingState,
    keyword,
    languages,
    location,
    page,
    q,
    registrationOpen,
    semester,
    serviceProviders,
    weekdays,
    zoom
  };
};

export default useSearch;
