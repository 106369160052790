import { HellewiParticipantCount } from '../api';

export enum Availability {
  sparesAvailable = 'sparesAvailable',
  full = 'full',
  almostfull = 'almostfull',
  available = 'available',
  registrationClosed = 'registrationClosed'
}
export const getAvailability = (
  pc: HellewiParticipantCount | undefined
): Availability | undefined => {
  if (!pc) {
    return undefined;
  }
  if (!pc.registrationopen) {
    return Availability.registrationClosed;
  } else if (pc.full && pc.sparefull) {
    return Availability.full;
  } else if (pc.full && !pc.sparefull) {
    return Availability.sparesAvailable;
  } else if (pc.almostfull && !pc.full) {
    return Availability.almostfull;
  } else if (!pc.almostfull && !pc.full) {
    return Availability.available;
  }
  return undefined;
};
