<style lang="scss" scoped>
.filter-item {
  white-space: normal !important;
}
@media (max-width: 1400px) {
  .button-group button {
    margin: 0.4rem;
  }
}
</style>

<template>
  <b-dropdown v-model="selected" aria-role="list" scrollable max-height="65vh">
    <b-button
      class="is-primary filter-button"
      :outlined="!Boolean(selected)"
      type="button"
      slot="trigger"
    >
      <span>{{ $t(`searchFilters.${type}`) }}</span>
    </b-button>

    <b-dropdown-item
      custom
      aria-role="listitem"
      v-if="type === 'serviceProviders'"
    >
      <b-input
        :placeholder="$t('serviceProviders.search')"
        class="search"
        v-model="searchValue"
      >
      </b-input>
    </b-dropdown-item>

    <!-- see explanation in showSelectedOptionSeparately -->
    <b-dropdown-item
      v-if="showSelectedOptionSeparately"
      :key="selected"
      :value="selected"
      aria-role="listitem"
      @click="optionClicked(selected)"
      class="filter-item"
    >
      {{ selectedName }}
    </b-dropdown-item>

    <b-dropdown-item
      v-for="option in filteredOptions"
      :key="option.id.toString()"
      :value="option.id.toString()"
      aria-role="listitem"
      @click="optionClicked(option.id.toString())"
      class="filter-item"
    >
      <span>{{ option.name }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  watch,
  PropType
} from '@vue/composition-api';
import { find, includes, map } from 'lodash/fp';

import useRouter from '../../hooks/useRouter';
import { translate } from '../../utils/misc-utils';

export interface FilterItemOption {
  id: string;
  name: string;
}

export default defineComponent({
  name: 'SearchFilterDropdown',
  props: {
    // filter item type, e.g. 'classifications', used for translation and
    // query updating via router
    // this has to be a plural term due to that router (there is support for
    // having multiple items selected at once even though it is disabled here)
    type: String as PropType<string>,
    // initial selection, or selection that is got from route through props
    // this has to be array due to reasons, but use the first one if there
    // are values
    initialSelection: Array as PropType<string[]>,
    // list of options
    options: Array as PropType<FilterItemOption[]>
  },
  setup: (props, context) => {
    const { pushRoute } = useRouter();

    const selected = ref<string | undefined>(
      props.initialSelection ? props.initialSelection[0] : undefined
    );
    const previous = ref<string | undefined>(
      props.initialSelection ? props.initialSelection[0] : undefined
    );

    const searchValue = ref<string>('');

    const filteredOptions = computed<FilterItemOption[] | undefined>(() => {
      return props.options?.filter((option) =>
        option.name.toUpperCase().includes(searchValue.value.toUpperCase())
      );
    });

    // if some other filter or search results in a query where options
    // for this dropdown filter don't include the selected option any more,
    // add it as first one so that user can see the selection and deselect
    // that one also
    const showSelectedOptionSeparately = computed<boolean>(
      () =>
        Boolean(selected.value) &&
        !includes(
          selected.value,
          map((option) => option.id.toString(), props.options)
        )
    );
    // selected name must be saved to a separate variable for
    // showSelectedOptionSeparately logic
    //
    // selected name is updated when the selection changes, but if
    // a page is loaded with query parameter that isn't included in the options,
    // we don't know the name of selected filter even though we know the
    // selection, so return 'unknown selection'
    const getSelectedName = () => {
      const option = find(
        (o) => o.id.toString() === selected.value,
        props.options
      );
      return option
        ? option.name
        : translate(context, 'searchFilters.unknownSelection');
    };

    const selectedName = ref<string | undefined>(getSelectedName());

    // when an option is clicked, check manually whether the selection should
    // be cleared as buefy doesn't have logic for that
    // (if clicked option was already selected, clear selection)
    const optionClicked = (clickId: string) => {
      if (clickId === previous.value) {
        selected.value = undefined;
      }
    };

    // update search through router and change page to first one,
    // save this selection as the previous one for optionClicked
    // and current selection name for selectedName
    watch(selected, (cur) => {
      if (props.type) {
        pushRoute({
          page: '1',
          [props.type]: cur ? String(cur) : ''
        });
        previous.value = cur;
        selectedName.value = getSelectedName();
      }
    });

    // update search value when props change
    watch(
      () => props.initialSelection,
      () => {
        selected.value = props.initialSelection
          ? props.initialSelection[0]
          : undefined;
      }
    );

    return {
      optionClicked,
      selected,
      showSelectedOptionSeparately,
      selectedName,
      searchValue,
      filteredOptions
    };
  }
});
</script>
