export const filterUndefineds = <T>(xs: (T | undefined)[]): T[] =>
  xs.filter((x) => x !== undefined) as T[];

// context too difficult to type correctly here I guess
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const translate = (context: any, i18nKey: string) => {
  if (context.parent && context.parent.$t) {
    const translation = context.parent.$t(i18nKey);

    if (translation) {
      return translation.toString();
    }
  }
  return i18nKey;
};
