<style lang="scss" scoped>
.button-group {
  display: flex;
  flex-direction: row;
}
.checkbox-group {
  label {
    margin-top: 1rem;
  }
}
@media (max-width: 1400px) {
  .button-group {
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>

<template>
  <div>
    <div class="button-group">
      <SearchFilterDropdown
        type="distanceLearning"
        :initialSelection="[distanceLearning]"
        :options="distanceLearningList"
      />

      <SearchFilterDropdown
        type="weekdays"
        :initialSelection="weekdays"
        :options="weekdaysList"
      />

      <SearchFilterDropdown
        type="semester"
        :initialSelection="[semester]"
        :options="semestersList"
      />

      <SearchFilterDropdown
        type="classifications"
        :initialSelection="classifications"
        :options="classificationsList"
      />

      <SearchFilterDropdown
        type="serviceProviders"
        :initialSelection="serviceProviders"
        :options="serviceProvidersList"
      />
    </div>
    <div class="button-group checkbox-group">
      <SearchFilterCheckbox
        v-for="filter in checkboxFilters"
        :filter="filter"
        :key="filter.name"
        :initialSelection="getInitialSelection(filter)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  PropType
} from '@vue/composition-api';
import { capitalize, find, map } from 'lodash/fp';

import { HellewiCatalog, HellewiCatalogItem } from '../../api';
import { currentYear, currentMonth, nextYear } from '../../utils/date-utils';
import { filterUndefineds, translate } from '../../utils/misc-utils';
import { DistanceLearning, Semester } from '../../utils/query-utils';

import SearchFilterDropdown, {
  FilterItemOption
} from './search-filter-dropdown.vue';

import SearchFilterCheckbox from './search-filter-checkbox.vue';

/**
 * Show always three semester options, the ongoing and two future ones.
 *
 * Options depend on the ongoing month:
 * - 01-07: spring, summer, autumn
 * - 08: summer, autumn, spring
 * - 09-12: autumn, spring, summer
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSemestersList = (context: any, month: number): FilterItemOption[] => {
  const options: { [key in Semester]: FilterItemOption } = {
    [Semester.CurrentYearSpring]: {
      id: Semester.CurrentYearSpring,
      name: `${translate(context, 'searchFilters.spring')} ${currentYear}`
    },
    [Semester.CurrentYearSummer]: {
      id: Semester.CurrentYearSummer,
      name: `${translate(context, 'searchFilters.summer')} ${currentYear}`
    },
    [Semester.CurrentYearAutumn]: {
      id: Semester.CurrentYearAutumn,
      name: `${translate(context, 'searchFilters.autumn')} ${currentYear}`
    },
    [Semester.NextYearSpring]: {
      id: Semester.NextYearSpring,
      name: `${translate(context, 'searchFilters.spring')} ${nextYear}`
    },
    [Semester.NextYearSummer]: {
      id: Semester.NextYearSummer,
      name: `${translate(context, 'searchFilters.summer')} ${nextYear}`
    },
    [Semester.NextYearAutumn]: {
      id: Semester.NextYearAutumn,
      name: `${translate(context, 'searchFilters.autumn')} ${nextYear}`
    }
  };

  if (1 <= month && month < 8) {
    return [
      options[Semester.CurrentYearSpring],
      options[Semester.CurrentYearSummer],
      options[Semester.CurrentYearAutumn]
    ];
  } else if (8 <= month && month < 9) {
    return [
      options[Semester.CurrentYearSummer],
      options[Semester.CurrentYearAutumn],
      options[Semester.NextYearSpring]
    ];
  } else if (9 <= month && month <= 12) {
    return [
      options[Semester.CurrentYearAutumn],
      options[Semester.NextYearSpring],
      options[Semester.NextYearSummer]
    ];
  } else {
    return [
      options[Semester.CurrentYearSpring],
      options[Semester.CurrentYearSummer],
      options[Semester.CurrentYearAutumn],
      options[Semester.NextYearSpring],
      options[Semester.NextYearSummer]
    ];
  }
};

export default defineComponent({
  name: 'SearchFilters',
  props: {
    weekdays: {
      type: Array as PropType<string[]>
    },
    classifications: {
      type: Array as PropType<string[]>
    },
    serviceProviders: {
      type: Array as PropType<string[]>
    },
    semester: {
      type: String
    },
    catalog: {
      type: Object as PropType<HellewiCatalog>
    },
    distanceLearning: {
      type: String
    },
    registrationOpen: {
      type: String
    },
    civicSkills: {
      type: String
    },
    creditCourse: {
      type: String
    }
  },
  components: { SearchFilterCheckbox, SearchFilterDropdown },
  setup: (props, context) => {
    const selectedSemester = ref<string>(props.semester ?? '');

    const weekdaysList: ComputedRef<FilterItemOption[]> = computed(() =>
      map(
        (weekday: HellewiCatalogItem) => ({
          id: weekday.id ? String(weekday.id) : '',
          name: capitalize(weekday.name)
        }),
        props?.catalog?.weekday || []
      )
    );

    const semestersList = getSemestersList(context, currentMonth);

    const classificationsList = computed(
      () => props?.catalog?.classification || []
    );

    const serviceProvidersList: ComputedRef<FilterItemOption[]> = computed(() =>
      map(
        (tenant: HellewiCatalogItem) => ({
          // This is a bit of an ugly hack, catalog item ids must be numbers
          // so they're null from API, and here they're supposed to be
          // 'demo.opistopalvelut.fi'.
          // keywords[0] is then again 'tenant:demo.opistopalvelut.fi', so
          // use that one and strip the beginning.
          id: tenant.keywords ? tenant.keywords[0].replace(/^tenant:/, '') : '',
          name: tenant.name
        }),
        props?.catalog?.tenant || []
      )
    );

    const distanceLearningList: FilterItemOption[] = [
      {
        id: DistanceLearning.True,
        name: `${translate(context, 'searchFilters.distanceLearning')}`
      },
      {
        id: DistanceLearning.False,
        name: `${translate(context, 'searchFilters.notDistanceLearning')}`
      }
    ];

    const checkboxFilters = computed(() => {
      const registrationOpenFilter = find(
        (filter) => filter.name === 'registrationopen',
        props?.catalog?.date || []
      );
      const creditcourseFilter = find(
        (filter) => filter.keywords?.indexOf('tag:creditcourse') !== -1,
        props?.catalog?.tag || []
      );
      const civicSkillsFilter = find(
        (filter) => filter.keywords?.indexOf('tag:civicskills') !== -1,
        props?.catalog?.tag || []
      );
      return filterUndefineds([
        registrationOpenFilter,
        creditcourseFilter,
        civicSkillsFilter
      ]);
    });

    const getInitialSelection = (filter: HellewiCatalogItem) => {
      if (filter.name === 'registrationopen') {
        return props.registrationOpen;
      } else if (filter.keywords?.indexOf('tag:creditcourse') !== -1) {
        return props.creditCourse;
      } else if (filter.keywords?.indexOf('tag:civicskills') !== -1) {
        return props.civicSkills;
      }
    };

    return {
      checkboxFilters,
      getInitialSelection,
      selectedSemester,
      weekdaysList,
      classificationsList,
      serviceProvidersList,
      currentYear,
      nextYear,
      currentMonth,
      semestersList,
      distanceLearningList
    };
  }
});
</script>
