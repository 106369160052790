<style lang="scss" scoped>
.content {
  background-color: $frontpage-bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 5rem 5rem 5rem;
}
.title {
  text-align: center;
  color: white;
  margin-bottom: 4rem;
  z-index: 100;
}

.tags-container {
  margin-right: 10rem;
  margin-left: 10rem;
}
.category-link {
  color: black;
  text-emphasis: none;
}
.imagecontainer {
  margin-bottom: -30px;
  width: 100%;
}
.image {
  width: 100%;
}
.eopisto .imagecontainer {
  margin-bottom: 0;
}

@media (max-width: 800px) {
  .image {
    min-height: 100%;
    min-width: 188%;
    margin-left: -40%;
  }
  .imagecontainer {
    overflow: hidden;
  }
}
</style>

<template>
  <section :class="{ eopisto: serviceName === 'E-opisto.fi' }">
    <div class="imagecontainer">
      <img
        v-if="serviceName !== 'E-opisto.fi'"
        class="image"
        src="../../frontend-assets/frontpage.svg"
        alt=""
      />
      <img
        v-if="serviceName === 'E-opisto.fi'"
        class="image"
        src="../../frontend-assets/frontpage.jpg"
        alt=""
      />
    </div>
    <div class="content">
      <div class="title has-text-weight-light">
        {{ $t('landerCategories.popularCategories') }}
      </div>
      <b-taglist v-if="classifications" class="tags-container">
        <div
          class="tag rounded is-medium"
          v-for="classification in classifications"
          :key="classification.id"
        >
          <router-link
            class="category-link"
            :to="{
              path: `/${language}/search?classifications=${classification.id}`
            }"
          >
            {{ classification.name }}
          </router-link>
        </div>
      </b-taglist>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import { HellewiCatalogItem } from '../api';
import { router } from '../router';
import { translate } from '../utils/misc-utils';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Categories',
  props: {
    classifications: Array as PropType<HellewiCatalogItem[]>
  },
  setup(props, ctx) {
    const { language } = router.currentRoute.params;
    return { language, serviceName: translate(ctx, 'footer.serviceName') };
  }
});
</script>
