import { isEmpty } from 'lodash/fp';

import { router } from '../router';

function useRouter() {
  function pushRoute({
    keyword = router.currentRoute.query.q,
    location = router.currentRoute.query.location,
    distance = router.currentRoute.query.distance,
    weekdays = router.currentRoute.query.weekdays,
    semester = router.currentRoute.query.semester,
    languages = router.currentRoute.query.languages,
    classifications = router.currentRoute.query.classifications,
    serviceProviders = router.currentRoute.query.serviceproviders,
    distanceLearning = router.currentRoute.query.distancelearning,
    page = router.currentRoute.query.page,
    registrationopen = router.currentRoute.query.registrationopen,
    creditcourse = router.currentRoute.query.creditcourse,
    civicskills = router.currentRoute.query.civicskills
  }) {
    const p = {
      query: {
        q: isEmpty(keyword) ? undefined : keyword,
        location: isEmpty(location) ? undefined : location,
        distance: isEmpty(distance) ? undefined : distance,
        weekdays: isEmpty(weekdays) ? undefined : weekdays,
        semester: isEmpty(semester) ? undefined : semester,
        languages: isEmpty(languages) ? undefined : languages,
        classifications: isEmpty(classifications) ? undefined : classifications,
        serviceproviders: isEmpty(serviceProviders)
          ? undefined
          : serviceProviders,
        registrationopen: isEmpty(registrationopen)
          ? undefined
          : registrationopen,
        distancelearning: isEmpty(distanceLearning)
          ? undefined
          : distanceLearning,
        creditcourse: isEmpty(creditcourse) ? undefined : creditcourse,
        civicskills: isEmpty(civicskills) ? undefined : civicskills,
        page: page === '1' ? undefined : page
      }
    };
    router.push(p);
  }

  function goToFrontPage() {
    const { language } = router.currentRoute.params;
    router.push(`/${language}`);
  }

  function goToCourse(id: number) {
    const { language } = router.currentRoute.params;
    router.push(`/${language}/course/${id}`);
  }

  function getCourseLink(id: number) {
    const { language } = router.currentRoute.params;
    return `/${language}/course/${id}`;
  }

  return { pushRoute, goToFrontPage, goToCourse, getCourseLink };
}

export default useRouter;
