import { ref } from '@vue/composition-api';
import { isEqual, memoize } from 'lodash/fp';
import PCancelable from 'p-cancelable';

import {
  Configuration,
  CatalogApi,
  CatalogApiInterface,
  HellewiCatalog
} from '../api';

import {
  Api,
  ApiEndpoint,
  ApiEndpointInitialization,
  RequestState
} from '../utils/api-utils';

/**
 * Use catalog API
 */
export const useCatalogApi: Api<CatalogApiInterface> = memoize(() => {
  const api = ref<CatalogApiInterface | undefined>(undefined);

  const changeConfiguration = (configuration: Configuration) => {
    api.value = new CatalogApi(configuration);
  };

  return {
    api,
    changeConfiguration
  };
});

export const useGetCatalogUnfiltered: ApiEndpoint<
  void,
  HellewiCatalog | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = useCatalogApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<HellewiCatalog | undefined>(initial);

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async () => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      // request already ongoing, don't start a new one
      state.value === RequestState.Loading ||
      // don't load again if this is already successfully loaded
      state.value === RequestState.Success
    ) {
      return;
    }

    try {
      state.value = RequestState.Loading;
      response.value = await api.value.getCatalog({});
      state.value = RequestState.Success;
    } catch {
      response.value = initial;
      state.value = RequestState.Error;
    }
  };

  return {
    initial,
    state,
    response,
    execute
  };
});

export const useGetCatalog: ApiEndpoint<
  string | undefined,
  HellewiCatalog | undefined
> = memoize(() => {
  const initial = undefined;
  const { api } = useCatalogApi();
  const state = ref<RequestState>(RequestState.Uninitialized);
  const response = ref<HellewiCatalog | undefined>(initial);
  const currentQ = ref<string | undefined>(undefined);
  const ongoing = ref<PCancelable<HellewiCatalog | undefined> | undefined>(
    undefined
  );

  ApiEndpointInitialization(api, state, response, initial);

  const execute = async (q: string | undefined) => {
    if (
      !api.value ||
      state.value === RequestState.Uninitialized ||
      // don't load again if this is already successfully loaded
      (state.value === RequestState.Success && isEqual(currentQ.value, q))
    ) {
      return;
    } else if (ongoing.value) {
      // cancel the previous ongoing load
      ongoing.value.cancel();
    }

    ongoing.value = new PCancelable(async (resolve, reject, onCancel) => {
      onCancel(() => reject('cancelled'));
      try {
        if (!api.value) {
          return;
        }
        const catalog = await api.value.getCatalog({ q });
        resolve(catalog);
      } catch {
        reject();
      }
    });

    try {
      state.value = RequestState.Loading;
      response.value = initial;
      response.value = await ongoing.value;
      currentQ.value = q;
      state.value = RequestState.Success;
    } catch (err) {
      if (err !== 'cancelled') {
        state.value = RequestState.Error;
      }
      // if this request was cancelled, don't touch the request state as the cancelling
      // request will handle the situation
    }
    ongoing.value = undefined;
  };

  return {
    initial,
    state,
    response,
    execute
  };
});
