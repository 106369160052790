<template>
  <footer class="footer">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-two-fifths">
          <div class="content">
            <h3 class="has-text-weight-light">
              {{
                $tc('footer.amountOfCourses', totalCourseCount, {
                  n:
                    totalCourseCount === 0
                      ? $t('footer.many')
                      : totalCourseCount
                })
              }}
            </h3>
          </div>
        </div>
        <div class="column"></div>
        <div class="column">
          <nav class="menu">
            <p class="menu-label">
              {{ $t('footer.languages') }}
            </p>
            <ul class="menu-list">
              <li>
                <a v-on:click="changeLanguage('fi')">Suomeksi</a>
              </li>
              <li>
                <a v-on:click="changeLanguage('sv')">På svenska</a>
              </li>
              <li>
                <a v-on:click="changeLanguage('en')">In English</a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="column">
          <nav class="menu">
            <p class="menu-label">{{ $t('footer.serviceName') }}</p>
            <ul class="menu-list">
              <li>
                <router-link :to="{ path: `/${language}/search` }">{{
                  $t('footer.browse')
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ path: `/${language}/service-providers` }">{{
                  $t('footer.serviceProviders')
                }}</router-link>
              </li>
              <li v-if="false">
                <router-link :to="{ path: `/${language}` }">{{
                  $t('footer.howTo')
                }}</router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="content has-text-centered mt-6">
        <p>{{ $t('footer.poweredBy') }}</p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount } from '@vue/composition-api';
import { router } from '../router';
import { useGetCourseCount } from '../hooks/useCourseApi';
import { changeLanguage } from '../i18n';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  setup: () => {
    const { language } = router.currentRoute.params;
    const { response, execute: getCourseCount } = useGetCourseCount();

    onBeforeMount(getCourseCount);

    const totalCourseCount = computed(() => response.value?.count);

    return {
      language,
      totalCourseCount,
      changeLanguage
    };
  }
});
</script>
