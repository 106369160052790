<style lang="scss" scoped>
.notfound-container {
  margin: 6rem 2rem 10rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-container {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.image {
  margin-right: 3rem;
}
.title {
  font-size: 1.8rem;
}
@media (max-width: 800px) {
  .notfound-container {
    flex-direction: column-reverse;
    margin-top: 3rem;
  }
  .image {
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .content-container {
    margin-right: 0;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .content-container {
    align-items: flex-start;
    margin: 0 auto;
  }
}
</style>

<template>
  <div>
    <Header />
    <div class="notfound-container">
      <div class="content-container">
        <h1 class="title">{{ $t('notFound.notFound') }}</h1>
        <p>{{ $t('notFound.explanation') }}</p>
        <router-link :to="{ path: '/' + language }">{{
          $t('notFound.goBack')
        }}</router-link>
      </div>
      <img
        src="../../frontend-assets/404.png"
        alt="Bird with sunglasses"
        class="image"
      />
    </div>
    <Footer :apiConfiguration="apiConfiguration" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import Header from '../components/header/header.vue';
import Footer from '../components/footer.vue';
import { Configuration } from '../api';
import { router } from '../router';

export default defineComponent({
  name: 'NotFound',
  components: {
    Header,
    Footer
  },
  props: {
    apiConfiguration: {
      type: Object as PropType<Configuration>,
      required: true
    }
  },
  setup: () => {
    const { language } = router.currentRoute.params;
    return { language };
  }
});
</script>
