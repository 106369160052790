import VueI18n from 'vue-i18n';
import { router } from './router';

import { default as messages } from '../frontend-assets/i18n.json';

export const initializeI18n = () =>
  new VueI18n({
    locale: 'fi',
    fallbackLocale: 'fi',
    numberFormats: {
      fi: {
        currency: {
          style: 'currency',
          currency: 'EUR'
        }
      },
      sv: {
        currency: {
          style: 'currency',
          currency: 'EUR'
        }
      },
      en: {
        currency: {
          style: 'currency',
          currency: 'EUR'
        }
      }
    },
    dateTimeFormats: {
      fi: {
        weekdayShort: {
          weekday: 'short'
        },
        weekdayLong: {
          weekday: 'long'
        }
      },
      en: {
        weekdayShort: {
          weekday: 'short'
        },
        weekdayLong: {
          weekday: 'long'
        }
      },
      sv: {
        weekdayShort: {
          weekday: 'short'
        },
        weekdayLong: {
          weekday: 'long'
        }
      }
    },
    silentFallbackWarn: true,
    messages
  });

export const changeLanguage = (language: 'fi' | 'sv' | 'en') => {
  router.push({ params: { language } });
  // Force a refresh, because pushing the params doesn't necessarily refresh the contents
  router.go(0);
};
