<style scoped>
.card-image {
  border-radius: 10px 10px 0 0;
  text-align: center;
  height: 8.5rem;
}
.cover-image {
  height: 100%;
  padding: 0.5rem;
}
.eopisto .cover-image {
  width: 100%;
  padding: 0;
}
.eopisto .cover-image img {
  object-fit: cover !important;
}
.card {
  width: 18rem;
  height: 15rem;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
.card-content {
  padding: 0.6rem;
  height: 6.8rem;
}
.title {
  font-size: 1.2rem;
  margin: 0 !important;
  line-height: 1.4rem;
  max-height: 2.8rem;
  z-index: 6;
}
.title-v-clamp {
  background: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  border-radius: 10px;
}
.info {
  font-size: 0.8rem;
  width: 100%;
  color: #999999;
  overflow: hidden;
  margin: 0 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 3;
}
.left-margin {
  margin-left: 0.8rem;
}

.icon {
  color: #b3b3b3;
  margin-right: -0.2rem;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.price {
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  z-index: 10;
  color: white;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.2);
  font-size: 1.2rem;
  padding: 0.2rem 0.8rem;
  border-radius: 10px;
}
.distancelearning {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
  color: white;
}
.availability {
  border-radius: 100px;
  height: 1.75rem;
  width: 1.75rem;
  border: solid white 0.3rem;
  background: #6ac873;
  position: absolute;
  top: 7.6rem;
  right: 0.6rem;
  z-index: 10;
}
.availability.type--almostfull {
  background: #ecc562;
}
.availability.type--sparesAvailable {
  background: #ff9970;
}
.availability.type--full {
  background: #d47076;
}
.availability.type--registrationClosed {
  background: #d47076;
}
.tooltip-placeholder {
  display: block;
  border-radius: 100px;
  height: 1.75rem;
  width: 1.75rem;
  margin: -0.3rem 0 0 -0.3rem;
  z-index: 15;
}
.weekday {
  text-transform: uppercase;
}
</style>
<template>
  <div class="card">
    <div v-if="course">
      <router-link :to="{ path: getCourseLink(course.id) }">
        <div class="price" v-if="getPriceEuros(getDefaultPrice(course))">
          {{ getPriceEuros(getDefaultPrice(course)) | price }}
        </div>

        <b-icon v-if="distanceLearning" icon="web" class="distancelearning" />

        <div class="availability" :class="`type--${availability}`">
          <b-tooltip type="is-light" :label="$t(`courseCard.${availability}`)">
            <div class="tooltip-placeholder"></div>
          </b-tooltip>
        </div>

        <div class="card-image" :style="{ background: color }">
          <img
            v-if="serviceName !== 'E-opisto.fi'"
            :src="imageUrl"
            alt="Cover image"
            class="cover-image"
          />
          <img
            v-if="serviceName === 'E-opisto.fi'"
            :src="imageUrl"
            alt="Cover image"
            class="cover-image"
            :style="{ objectFit: 'cover' }"
          />
        </div>
        <div class="card-content flex-column">
          <p class="title">
            <v-clamp
              class="title-v-clamp"
              v-on:mouseover.native="expanded = true"
              v-on:mouseleave.native="expanded = false"
              :expanded="expanded"
              :max-lines="2"
              >{{ decodedName }}</v-clamp
            >
          </p>
          <div class="">
            <p v-if="course.location" class="info">
              <b-icon class="icon" icon="map-marker" size="is-small" />
              <span v-if="course.location.city">
                {{ course.location.city }},
              </span>
              {{ course.location.name }}
            </p>
            <span class="info">
              <b-tooltip
                type="is-light"
                position="is-bottom"
                :label="$t('courseCard.courseStartDate')"
              >
                <b-icon class="icon" icon="calendar-today" size="is-small" />
                <span v-if="course.begins">
                  {{ course.begins | date }}
                </span>
                <span v-else>
                  {{ `${$t('courseCard.notprovided')} ` }}
                </span>
              </b-tooltip>
            </span>
            <span class="info" v-if="formattedWeekdays.length !== 0">
              <b-icon
                class="icon left-margin"
                icon="calendar-clock"
                size="is-small"
              />
              <span v-if="formattedWeekdays.length === 7">
                {{ $t('courseCard.monday') }}&ndash;{{
                  $t('courseCard.sunday')
                }}
              </span>
              <span v-if="formattedWeekdays.length < 2">
                <span
                  v-for="day in sortBy('weekday', formattedWeekdays)"
                  :key="String(day.weekday) + day.times[0].begins"
                >
                  <span v-if="day.weekday" class="weekday">
                    {{ $d(day.weekday, 'weekdayShort') + ' ' }}
                  </span>
                  <span v-if="day.times.length > 0 && day.times.length < 2">
                    {{ day.times[0].begins }}&ndash;{{ day.times[0].ends }}
                  </span>
                </span>
              </span>
              <span
                v-if="
                  formattedWeekdays.length > 1 && formattedWeekdays.length < 7
                "
              >
                <span
                  v-for="day in sortBy('weekday', formattedWeekdays)"
                  :key="String(day.weekday)"
                >
                  <span v-if="day.weekday" class="weekday">
                    {{ $d(day.weekday, 'weekdayShort') + ' ' }}
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
      </router-link>
    </div>

    <div v-else>
      <b-skeleton width="100%" height="136px" :animated="true"></b-skeleton>

      <div class="card-content">
        <b-skeleton width="40%" :animated="true"></b-skeleton>
        <b-skeleton width="60%" :animated="true"></b-skeleton>
        <b-skeleton width="90%" :animated="true"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { decode } from 'html-entities';
import { sortBy } from 'lodash/fp';
import VClamp from 'vue-clamp';

import useRouter from '../../hooks/useRouter';
import { ListCoursesResponseCourse } from '../../hooks/useCourseApi';
import { getAvailability, Availability } from '../../utils/availability-utils';
import { formatWeekdays } from '../../utils/date-utils';
import { getDefaultPrice, getPriceEuros } from '../../utils/course-utils';

import { translate } from '../../utils/misc-utils';

import {
  educationSectorMappings,
  DEFAULT_COLOR,
  DEFAULT_IMAGE
} from './education-sectors';

export default defineComponent({
  name: 'CourseCard',
  props: {
    course: {
      type: Object as PropType<ListCoursesResponseCourse>
    }
  },
  components: {
    VClamp
  },

  setup: (props, ctx) => {
    const { getCourseLink } = useRouter();

    const educationSectorId = props.course?.catalogitems.find(
      (item) => item.type === 'educationsector'
    )?.id;

    const availability = computed<Availability | undefined>(() =>
      getAvailability(props.course?.participantcount)
    );

    const educationSectorMapping = educationSectorId
      ? educationSectorMappings[educationSectorId]
      : null;

    const color = educationSectorMapping?.color || DEFAULT_COLOR;
    const imageUrl = educationSectorMapping?.imageUrl || DEFAULT_IMAGE;

    const expanded = false;

    const formattedWeekdays = formatWeekdays(props.course?.days);

    const decodedName = decode(props.course?.name);

    const distanceLearning = props.course?.catalogitems.find((item) =>
      item.keywords?.includes('location:distancelearning')
    );

    return {
      getDefaultPrice,
      getPriceEuros,
      availability,
      getCourseLink,
      color,
      imageUrl,
      sortBy,
      expanded,
      formattedWeekdays,
      decodedName,
      distanceLearning,
      serviceName: translate(ctx, 'footer.serviceName')
    };
  }
});
</script>
