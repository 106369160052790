import {
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  isValid,
  setISODay,
  startOfDay,
  subMinutes
} from 'date-fns';

// use custom formatters for dates and times

export const DATE_FORMAT_FI = 'd.M.yyyy';
export const DATETIME_FORMAT_FI = 'd.M.yyyy H:mm';
export const TIME_FORMAT_FI = 'H:mm';
export const TIME_SECONDS_FORMAT_FI = 'H:mm:ss';

/**
 * Formats a date in Finnish time format H:mm, e.g.
 * 12:04
 *
 * @param date date object, possibly null
 * @return time as string
 */
export const formatTime = (date: Date | null | undefined) =>
  date && isValid(date) ? format(date, TIME_FORMAT_FI) : '';

/**
 * Formats a date in Finnish date format d.M.yyyy, e.g.
 * 1.5.2020
 *
 * @param date date object, possibly null
 * @return date as string
 */
export const formatDate = (date: Date | null | undefined) =>
  date && isValid(date) ? format(date, DATE_FORMAT_FI) : '';

/**
 * Formats a date in Finnish date-time format d.M.yyyy H:mm, e.g.
 * 1.5.2020 12:04
 *
 * @param date date object, possibly null
 * @return time as string
 */
export const formatDateTime = (date: Date | null | undefined) =>
  date && isValid(date) ? format(date, DATETIME_FORMAT_FI) : '';

/**
 * Formats a time range in Finnish time format H:mm-H:mm, e.g.
 * 12:00-12:05
 *
 * @param begins date object, possibly null
 * @param ends date object, possibly null
 * @return time range as string
 */
export const formatTimeRange = (
  begins: Date | null | undefined,
  ends: Date | null | undefined
): string => {
  if (begins && ends && isValid(begins) && isValid(ends)) {
    return `${formatTime(begins)}\u2013${formatTime(ends)}`;
  } else if (begins && isValid(begins)) {
    return formatTime(begins);
  } else if (ends && isValid(ends)) {
    return formatTime(ends);
  } else {
    return '';
  }
};

/**
 * Formats a date range in Finnish date format d.M.yyyy-d.M.yyyy, e.g.
 * 1.1.2020-1.5.2020
 *
 * If the dates are the same, return that 1.1.2020
 * If the dates have the same month and year, return 1.-2.1.2020
 * If the dates have the same year, return 1.1.-1.2.2020
 *
 * @param begins date object, possibly null
 * @param ends date object, possibly null
 * @return time range as string
 */
export const formatDateRange = (
  begins: Date | undefined | null,
  ends: Date | undefined | null
): string => {
  if (
    begins &&
    ends &&
    isValid(begins) &&
    isValid(ends) &&
    isSameDay(begins, ends)
  ) {
    return formatDate(begins);
  } else if (begins && ends && isValid(begins) && isValid(ends)) {
    const beginsFormat =
      isSameMonth(begins, ends) && isSameYear(begins, ends)
        ? 'd.'
        : isSameYear(begins, ends)
          ? 'd.M.' // prettier-ignore
          : DATE_FORMAT_FI; // prettier-ignore

    return `${format(begins, beginsFormat)}\u2013${formatDate(ends)}`;
  } else if (begins && isValid(begins)) {
    return formatDate(begins);
  } else if (ends && isValid(ends)) {
    return formatDate(ends);
  } else {
    return '';
  }
};

/**
 * Formats a date-time range in Finnish date-time format d.M.yyyy H:mm, e.g.
 * 1.1.2020 12:00 - 1.2.2020 12:05
 *
 * If dates are the same, return 1.1.2020 12:00-12:05
 * If only one is given, return it, 1.1.2020 12:00
 *
 * @param begins date object, possibly null
 * @param ends date object, possibly null
 * @return time range as string
 */
export const formatDateTimeRange = (
  begins: Date | null | undefined,
  ends: Date | null | undefined
): string => {
  if (
    begins &&
    ends &&
    isValid(begins) &&
    isValid(ends) &&
    isSameDay(begins, ends)
  ) {
    return `${formatDateTime(begins)}\u2013${formatTime(ends)}`;
  } else if (begins && ends && isValid(begins) && isValid(ends)) {
    return `${formatDateTime(begins)} \u2013 ${formatDateTime(ends)}`;
  } else if (begins && isValid(begins)) {
    return formatDateTime(begins);
  } else if (ends && isValid(ends)) {
    return formatDateTime(ends);
  } else {
    return '';
  }
};

/**
 * Subtracts one minute from date if it is midnight (d.M.yyyy 00:00)
 *
 * If date is e.g. 2.2.2021 00:00, return 1.2.2021 23:59
 * If date is e.g. 2.2.2021 12:00, return 2.2.2021 12:00
 *
 * @param date date object, possibly null
 * @return time as string
 */
export const formatMidnight = (date: Date | null | undefined): string => {
  if (!date) {
    return '';
  }
  if (date.getTime() === startOfDay(date).getTime()) {
    return formatDateTime(subMinutes(date, 1));
  } else {
    return formatDateTime(date);
  }
};

/**
 * Formats price in European price format EUR,cc €, e.g.
 * 20,50 €
 *
 * If price have desimals, return 20,50 €
 * If there are no decimals, return 20€
 *
 * @param price number, possibly null
 * @return price as string or undefined
 */

export const formatPrice = (
  price: number | null | undefined
): string | undefined => {
  if (price && price % 1 !== 0) {
    return price?.toLocaleString('fi-FI', {
      style: 'currency',
      currency: 'EUR'
    });
  } else {
    return price?.toLocaleString('fi-FI', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });
  }
};

export const fakeDateFromWeekday = (
  weekday: number | undefined
): Date | undefined => {
  if (weekday != null) {
    return setISODay(new Date(), weekday);
  } else {
    return undefined;
  }
};
