<style scoped>
.headline {
  padding: 3rem;
  text-align: center;
}
@media all and (max-width: 1023px) {
  .headline {
    padding: 1rem 10rem;
    text-align: left;
  }
}
@media all and (max-width: 800px) {
  .headline {
    padding: 2rem 6rem;
  }
}
@media all and (max-width: 550px) {
  .headline {
    padding: 1.6rem 2rem;
  }
}
.content--full-height {
  width: 100%;
  min-height: 30rem;
  padding: 0;
}
</style>

<template>
  <div>
    <Header />

    <section class="section content--full-height">
      <h1 class="title has-text-weight-light headline">
        {{ $t('index.title') }}
      </h1>

      <LanderSearch
        @locating-error="onLocatingError"
        @locating-successful="onLocatingSuccessful"
      />
    </section>
    <section>
      <Categories :classifications="popularClassifications" />
    </section>
    <Footer />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  watch
} from '@vue/composition-api';
import orderBy from 'lodash/fp/orderBy';

import { HellewiCatalogItem } from '../api';
import { useGetCatalogUnfiltered } from '../hooks/useCatalogApi';
import { stateHasError, useErrorToast } from '../utils/api-utils';

import Header from '../components/header/header.vue';
import Footer from '../components/footer.vue';
import LanderSearch from '../components/lander-search.vue';
import Categories from '../components/lander-categories.vue';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Index',
  components: {
    Header,
    LanderSearch,
    Footer,
    Categories
  },
  setup: (props, ctx) => {
    const {
      response: catalog,
      execute: getCatalog,
      state
    } = useGetCatalogUnfiltered();
    const getCatalogHasError = stateHasError(state);

    const popularClassifications = computed<HellewiCatalogItem[]>(() =>
      orderBy('coursecount', 'desc', catalog.value?.classification)
    );

    onMounted(getCatalog);

    const { warnToast, clearErrorToasts } = useErrorToast(ctx);

    const onLocatingError = () => {
      warnToast('landerSearch.locatingError');
    };

    const onLocatingSuccessful = () => {
      clearErrorToasts();
    };

    onBeforeUnmount(clearErrorToasts);

    watch(getCatalogHasError, (hasErrorNow, hadErrorBefore) => {
      if (hasErrorNow) {
        warnToast('index.loadingError');
      } else if (hadErrorBefore) {
        clearErrorToasts();
      }
    });

    return { onLocatingError, onLocatingSuccessful, popularClassifications };
  }
});
</script>
