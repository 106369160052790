<style scoped>
.nav-container {
  top: 10px;
  padding: 3.8rem 4rem;
}

@media all and (max-width: 1023px) {
  .nav-container {
    padding: 5.25rem 9.25rem;
  }
}
@media all and (max-width: 800px) {
  .nav-container {
    padding: 5.25rem;
  }
}
@media all and (max-width: 550px) {
  .nav-container {
    padding: 1.25rem;
  }
}
</style>

<template>
  <b-navbar class="nav-container">
    <template slot="brand">
      <Logo />
    </template>
    <template slot="end">
      <b-navbar-item
        tag="router-link"
        :to="{
          path: `/${language}/search`
        }"
      >
        {{ $t('header.browse') }}</b-navbar-item
      >
      <b-navbar-item
        tag="router-link"
        :to="{ path: `/${language}/service-providers` }"
      >
        {{ $t('header.serviceProviders') }}
      </b-navbar-item>
      <LanguageSelection />
    </template>
  </b-navbar>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

import LanguageSelection from './language-selection.vue';
import Logo from './logo.vue';
import { router } from '../../router';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  components: {
    LanguageSelection,
    Logo
  },
  setup: () => {
    const { language } = router.currentRoute.params;
    return { language };
  }
});
</script>
