<style scoped>
.mobile-tenant {
  border-radius: 10px;
  margin: 0.5rem 0;
}

.wrapper {
  margin: 3rem;
}

.tenants {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-style {
  text-transform: uppercase;
  margin-left: 2rem;
}

.browse-style {
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 2rem;
}

.card-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -1rem;
}

.icons {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.icon-large {
  color: #b3b3b3;
  margin: 0.3rem 0 0 1rem;
}

.icon-small {
  color: #999999;
  margin-right: 0.2rem;
}

.search {
  width: 60%;
  margin: 50px auto;
}

.links-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -2rem;
  margin-top: 1rem;
}

.info-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-top: 1rem;
}

.info {
  margin-left: 1rem;
}

.button-style {
  display: none;
}

@media (min-width: 600px) {
  .tenants {
    margin: 2rem;
  }
}

@media (max-width: 505px) {
  .browse-style {
    display: none;
  }
  .links-wrapper {
    flex-direction: column;
    margin: 1rem 0 0 0;
    width: 100%;
  }
  .button-style {
    display: block;
    width: 100%;
    margin: 1rem 0 0 0;
  }
  .router-style {
    width: 100%;
  }
  .icon-large {
    margin: 0;
  }
  .icons {
    margin: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 2rem);
    justify-content: space-between;
  }
  .wrapper {
    margin: 2rem;
  }
  .search {
    margin: 20px 0;
    width: 100%;
  }
}
</style>

<template>
  <div>
    <Header />

    <div v-if="tenants" class="wrapper">
      <b-field>
        <b-input
          :placeholder="$t('serviceProviders.search')"
          type="is-primary"
          icon-right="magnify"
          class="search"
          v-model="searchValue"
        >
        </b-input>
      </b-field>
      <div class="tenants">
        <div v-for="tenant in filteredTenants" :key="tenant.tenant">
          <TenantDropdown
            :tenant="tenant"
            :browseLink="true"
            :detailsText="false"
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch
} from '@vue/composition-api';

import { router } from '../router';
import { useListTenants } from '../hooks/useTenantApi';
import { stateHasError, useErrorToast } from '../utils/api-utils';

import Header from '../components/header/header.vue';
import Footer from '../components/footer.vue';
import TenantDropdown from '../components/service-providers/tenant-dropdown.vue';

export default defineComponent({
  name: 'ServiceProviders',
  components: {
    Header,
    Footer,
    TenantDropdown
  },
  setup: (props, ctx) => {
    const { state, response: tenants, execute: listTenants } = useListTenants();
    const listTenantsHasError = stateHasError(state);
    const current = ref(1);
    const perPage = ref(10);
    const searchValue = ref('');

    onBeforeMount(listTenants);

    const filteredTenants = computed(() =>
      tenants.value.filter((tenant) =>
        tenant.name.toUpperCase().includes(searchValue.value.toUpperCase())
      )
    );

    const { warnToast } = useErrorToast(ctx);

    watch(listTenantsHasError, (hasErrorNow) => {
      if (hasErrorNow) {
        warnToast('serviceProviders.loadingError');
        // There isn't any content in this page if loading fails.
        // Probably fails on the previous page as well, but index and
        // search look more nice with no content.
        router.backOrFrontpage();
      }
    });

    return {
      tenants,
      state,
      current,
      filteredTenants,
      perPage,
      searchValue
    };
  }
});
</script>
