<style lang="scss" scoped>
.header-mobile {
  padding: 2.7rem 2rem 2rem 2rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
}
.back-button {
  color: $primary;
  width: 4rem;
  height: 4rem;
}
.menu-button {
  color: $primary;
  width: 4rem;
  height: 4rem;
}
.side-icon {
  display: flex;
  align-self: center;
}
</style>

<template>
  <nav class="header-mobile">
    <b-button @click="goBack" class="back-button" rounded>
      <b-icon class="side-icon" icon="arrow-left" size="is-medium"> </b-icon
    ></b-button>
    <Logo :width="logoWidth" />
    <b-button class="menu-button" rounded @click="menuModalActive = true">
      <b-icon class="side-icon" icon="menu" size="is-medium"> </b-icon
    ></b-button>
    <b-modal v-model="menuModalActive" :width="640" scroll="keep">
      <div class="content notification">
        <b-navbar-item tag="router-link" :to="{ path: `/${language}/search` }">
          {{ $t('header.browse') }}</b-navbar-item
        >
        <b-navbar-item
          tag="router-link"
          :to="{ path: `/${language}/service-providers` }"
        >
          {{ $t('header.serviceProviders') }}
        </b-navbar-item>
        <LanguageSelection />
      </div>
    </b-modal>
  </nav>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref
} from '@vue/composition-api';
import LanguageSelection from './language-selection.vue';
import Logo from './logo.vue';
import { router } from '../../router';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  components: {
    LanguageSelection,
    Logo
  },
  setup: () => {
    const menuModalActive = ref(false);

    const windowWidth = ref(window.innerWidth);

    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
    };
    onMounted(() => window.addEventListener('resize', onWidthChange));
    onUnmounted(() => window.removeEventListener('resize', onWidthChange));

    const logoWidth = computed<string>(() => {
      if (windowWidth.value < 385) {
        return '100px';
      } else if (windowWidth.value < 450) {
        return '150px';
      } else {
        return '200px';
      }
    });

    const goBack = () => {
      router.backOrFrontpage();
    };

    const { language } = router.currentRoute.params;

    return {
      menuModalActive,
      goBack,
      logoWidth,
      language
    };
  }
});
</script>
