<style lang="scss">
@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
.marker-cluster {
  background-color: $primary;
  border-radius: 100px;
  text-align: center;
  line-height: 38px;
  font-size: 1.3em;
  font-weight: bold;
  color: white;
}
.leaflet-popup-content {
  margin: -2px;
}
</style>

<template>
  <l-map
    :key="zoom + coordinates.toString()"
    :zoom="zoom"
    :center="[coordinates.lat, coordinates.lon]"
    :options="MAP_OPTIONS"
  >
    <l-control-zoom></l-control-zoom>
    <l-tile-layer :url="URL" />
    <v-marker-cluster v-if="courses" class="cluster">
      <l-marker
        :vif="markers"
        :lat-lng="item.position"
        v-for="(item, index) in markers"
        :key="index"
        :icon="getIcon(item)"
      >
        <l-popup>
          <CourseCard :key="item.id" :course="getCourse(courses, item.id)" />
        </l-popup>
      </l-marker>
    </v-marker-cluster>
  </l-map>
</template>

<script lang="ts">
import { latLng } from 'leaflet';
import { LMap, LTileLayer, LMarker, LControlZoom, LPopup } from 'vue2-leaflet';
import { computed, PropType, defineComponent } from '@vue/composition-api';
import { divIcon } from 'leaflet';
import { HellewiLocation } from '../../api';
import { HellewiCoursePartial } from '@/frontend-src/api';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import CourseCard from '../course-list/course-card.vue';
import { getAvailability, Availability } from '../../utils/availability-utils';

const URL = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';
const MAP_OPTIONS = {
  zoomSnap: 0.5,
  scrollWheelZoom: false,
  zoomControl: false
  // zoomAnimation: false,
  // fadeAnimation: false,
  // worldCopyJump: true,
  // noBlockingAnimations: true,
  // inertia: false,
};

export interface MarkerLocation {
  position: number[];
  name: string;
  id: string;
}

function getCourse(courses: HellewiCoursePartial[], courseId: string) {
  return courses.find((course: HellewiCoursePartial) => course.id === courseId);
}

function getIcon(course: HellewiCoursePartial) {
  const availability = getAvailability(course.participantcount);
  const color = !course.registrationopen
    ? '#D47076'
    : availability === Availability.full
    ? '#D47076'
    : availability === Availability.almostfull
    ? '#ECC562'
    : availability === Availability.sparesAvailable
    ? '#ff9970'
    : '#6AC873';
  return divIcon({
    className: 'my-custom-pin',
    iconSize: [30, 50],
    html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="50" width="30">
      <g transform="translate(-814.59595,-274.38623)">
        <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
          <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="fill:${color};stroke:${color};"/>
          <circle r="3.0355" cy="288.25278" cx="823.03064" id="path3049" style="display:inline;fill:transparent;"/>
        </g>
      </g>
    </svg>`
  });
}

export default defineComponent({
  name: 'SearchMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    LPopup,
    CourseCard,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  props: {
    coordinates: {
      type: Object as PropType<{ lat: number; lon: number }>,
      required: true
    },
    zoom: {
      type: Number,
      required: true
    },
    locations: {
      type: Array as PropType<HellewiLocation[]>,
      required: true
    },
    courses: {
      type: Array as PropType<HellewiCoursePartial[]>,
      required: true
    }
  },
  setup(props) {
    const markers = computed<MarkerLocation[]>(() => {
      const filteredData = props.courses?.filter(
        (course) => course.location?.latlon?.lat && course.location.latlon.lon
      );
      const markerData = filteredData?.map((course) => {
        return {
          position: [
            course.location?.latlon?.lat,
            course.location?.latlon?.lon
          ],
          name: course.name,
          id: course.id,
          registrationopen: course.registrationopen
        } as MarkerLocation;
      });
      return markerData;
    });

    return {
      markers,
      URL,
      MAP_OPTIONS,
      latLng,
      getIcon,
      getCourse
    };
  }
});
</script>
